import Vue from 'vue'
import Router from 'vue-router'
import Index from '../views/Index.vue'
import News from '../views/News.vue'
import Newsdetail from '../views/Newsdetail.vue'
import Project from '../views/Project.vue'
import Cooperation from '../views/Cooperation.vue'
import Contactus from '../views/Contactus.vue'
import Joinus from '../views/Joinus.vue'


Vue.use(Router)

export default new Router({
  base: '/',
  routes: [
    {
      path: '/',
      redirect: "/index",
    },
    {
      path: '/index',
      name: 'index',
      component: Index
    },
    {
      path: '/news',
      name: 'news',
      component: News,
    },
    {
      path: '/newsdetail',
      name: 'newsdetail',
      component: Newsdetail
    },
    {
      path: '/project',
      name: 'project',
      component: Project
    },
    {
      path: '/cooperation',
      name: 'cooperation',
      component: Cooperation
    },
    {
      path: '/contactus',
      name: 'contactus',
      component: Contactus
    },
    {
      path: '/joinus',
      name: 'joinus',
      component: Joinus
    },
  ]
})