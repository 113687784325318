<template>
  <div>
    <div class="news_bg">
      <div class="lagins">
        <div>
          <img src="../assets/images/logo.png" alt="" />
        </div>
        <div class="header_left">
          <HeaderNav></HeaderNav>
          <div class="lagins_Language">
            <Language style="width: 100%; height: 100%"></Language>
          </div>
        </div>
      </div>
      <img src="../assets/images/chanpinbanner.jpg" alt="" />
      <div class="news_bg_adlist">{{ $t("message.ProductPortfolio") }}</div>
    </div>
    <div class="project_cen_top">
      <div class="project_top_centers">
        <div class="project_top_centers_top">
          <span>{{ $t("message.ProductPortfolio") }}</span>
          <div class="project_top_centers_border"></div>
        </div>
        <div class="project_top_centers_bottom">
          <div
            class="project_top_li"
            v-for="(item, index) in lsitImg"
            :key="index"
            @click="clickToHove(index)"
          >
            <img :src="item.img" alt="" />
            <div class="project_top_li_text" :class="index==0?'project_top_li_text1':''">
              {{ $t(item.name) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="project_center">
      <div class="project_center_one" id="targetboxOne" :class="scolle>targetboxOne?'project_center_one1':''">
        <div class="project_one_left">
          <img src="../assets/images/20.png" alt="" />
        </div>
        <div class="project_one_right">
			<div class="spot"></div>
          <div class="titles">
            {{ $t("message.ProductPortfController") }}
			<div class="spans"></div>
          </div>
          <div class="p_text">
            {{ $t("message.EmccText") }}
            {{ $t("message.EmccTextOne") }}
          </div>
          <div class="lsit_uls">
            <div class="lsit_title">
              <span class="lsit_title_ridon"></span> OZ788WR2
            </div>
            <div class="lsit_li">
              {{ $t("message.TextOne") }}<br />
              {{ $t("message.TextTwo") }}<br />
              {{ $t("message.TextTher") }}
            </div>
          </div>
          <div class="lsit_uls">
            <div class="lsit_title">
              <span class="lsit_title_ridon"></span> BH720FJ1
            </div>
            <div class="lsit_li">
              {{ $t("message.ModernOne") }}<br />
              {{ $t("message.ModernTwo") }}<br />
              {{ $t("message.ModernTher") }}
            </div>
          </div>
          <div class="lsit_uls">
            <div class="lsit_title">
              <span class="lsit_title_ridon"></span> BH799BB
            </div>
            <div class="lsit_li">
              {{ $t("message.PcienOne") }}<br />
              {{ $t("message.PcienTwo") }}<br />
              {{ $t("message.PcienTher") }}
            </div>
          </div>
        </div>
      </div>
      <div class="project_center_two" id="targetboxTwo" :class="scolle>targetboxTwo?'project_center_two1':''">
        <div class="project_center_two_left">
			<div class="spot1"></div>
          <div class="project_two_title">
			{{ $t("message.MediaSD") }}
			<div class="project_two_apn" style="background: #f8ba46"> </div>
          </div>
          <div class="project_two_p">
            {{ $t("message.MediaSDtext") }}
          </div>
          <div class="project_uls">
            <div class="project_two_ul">
              <div class="project_two_li_header">
                <span
                  class="project_two_li_header_span"
                  style="background: #f8ba46"
                ></span>
                BH622FJ1
              </div>
              <div class="project_two_li">
                {{ $t("message.SD_j_one") }}<br />
                {{ $t("message.SD_j_two") }}<br />
                {{ $t("message.SD_j_ther") }}
              </div>
            </div>
            <div class="project_two_ul">
              <div class="project_two_li_header">
                <span
                  class="project_two_li_header_span"
                  style="background: #f8ba46"
                ></span>
                BH760FJ2
              </div>
              <div class="project_two_li">
                {{ $t("message.SD_f_one") }}<br />
                {{ $t("message.SD_f_two") }}<br />
                {{ $t("message.SD_f_ther") }}
              </div>
            </div>
            <div class="project_two_ul">
              <div class="project_two_li_header">
                <span
                  class="project_two_li_header_span"
                  style="background: #f8ba46"
                ></span>
                OZ711LV2
              </div>
              <div class="project_two_li">
                {{ $t("message.SD_lv_one") }}<br />
                {{ $t("message.SD_lv_two") }}<br />
                {{ $t("message.SD_lv_ther") }}
              </div>
            </div>
            <div class="project_two_ul">
              <div class="project_two_li_header">
                <span
                  class="project_two_li_header_span"
                  style="background: #f8ba46"
                ></span>
                BH770GG7
              </div>
              <div class="project_two_li">
                {{ $t("message.SD_g_one") }}<br />
                {{ $t("message.SD_g_two") }}<br />
                {{ $t("message.SD_g_ther") }}
              </div>
            </div>
            <div class="project_two_ul">
              <div class="project_two_li_header">
                <span
                  class="project_two_li_header_span"
                  style="background: #f8ba46"
                ></span>
                BH201
              </div>
              <div class="project_two_li">
                {{ $t("message.SD_bh_one") }}<br />
                {{ $t("message.SD_bh_two") }}<br />
                {{ $t("message.SD_bh_ther") }}
              </div>
            </div>
          </div>
        </div>
        <div class="project_center_two_right">
          <img src="../assets/images/30.png" alt="" />
        </div>
      </div>
      <div class="project_center_one" style="margin-top: 0.7rem;" id="targetboxTher" :class="scolle>targetboxTher?'project_center_one1':''">
        <div class="project_one_left">
          <img src="../assets/images/40.png" alt="" />
        </div>
        <div class="project_one_right">
			<div class="spot2"></div>
          <div class="titles">
            <span>{{$t("message.Storage_title") }}</span>
			<div class="spans" style="background: #00d577"></div>
          </div>
          <div class="p_text">
            {{ $t("message.StorageTextOne") }}<br />
			<br />
            {{ $t("message.StorageTextTwo") }}
          </div>
          <div class="lsit_uls">
            <div class="lsit_title" style="color: #00d577">
              <span class="lsit_title_ridon" style="background: #00d577"></span>
              OZ787FJ2
            </div>
            <div class="lsit_li">
              {{ $t("message.Storage_f_one") }}<br />
              {{ $t("message.Storage_f_two") }}
            </div>
          </div>
          <div class="lsit_uls">
            <div class="lsit_title" style="color: #00d577">
              <span class="lsit_title_ridon" style="background: #00d577"></span>
              OZ797SD2
            </div>
            <div class="lsit_li">
              {{ $t("message.Storage_sd_one") }}<br />
              {{ $t("message.Storage_sd_two") }}<br />
              {{ $t("message.Storage_sd_ther") }}
            </div>
          </div>
        </div>
      </div>
      <div class="project_center_two" id="targetboxFover" :class="scolle>targetboxFover?'project_center_two1':''">
        <div class="project_center_two_left">
			<div class="spot3"></div>
          <div class="project_two_title">
            {{ $t("message.cardHerder") }}
			<div class="project_two_apn" style="background: #66cbe5"> </div>
          </div>
          <div class="project_two_p">
            {{ $t("message.card_text") }}
          </div>
          <div class="project_uls1">
            <div class="project_two_ul">
              <div class="project_two_li_header" style="color: #66cbe5">
                <span
                  class="project_two_li_header_span"
                  style="background: #66cbe5"
                ></span>
                OZ600TJ0
              </div>
              <div class="project_two_li">
                {{ $t("message.card_tj_one") }}<br />
                {{ $t("message.card_tj_two") }}<br />
                {{ $t("message.card_tj_ther") }}<br />
                {{ $t("message.card_tj_forv") }}
              </div>
            </div>
            <div class="project_two_ul">
              <div class="project_two_li_header" style="color: #66cbe5">
                <span
                  class="project_two_li_header_span"
                  style="background: #66cbe5"
                ></span>
                OZ2210
              </div>
              <div class="project_two_li">
                {{ $t("message.card_num_one") }}<br />
                {{ $t("message.card_num_two") }}<br />
				{{ $t("message.card_num_two1") }}
              </div>
            </div>
            <div class="project_two_ul">
              <div class="project_two_li_header" style="color: #66cbe5">
                <span
                  class="project_two_li_header_span"
                  style="background: #66cbe5"
                ></span>
                OZ77CR6
              </div>
              <div class="project_two_li">
                {{ $t("message.card_cr_one") }}<br />
                {{ $t("message.card_cr_two") }}<br />
                {{ $t("message.card_cr_ther") }}<br />
                {{ $t("message.card_cr_forv") }}<br />
				{{ $t("message.card_cr_five") }}
              </div>
            </div>
          </div>
        </div>
        <div class="project_center_two_right">
          <img src="../assets/images/50.png" alt="" />
        </div>
      </div>
    </div>
	<topsa></topsa>
    <Tooters></Tooters>
  </div>
</template>

<script>
import Language from "../components/language";
import HeaderNav from "../components/headernav";
import Tooters from "../components/footers";
import topsa from '../components/topsa.vue';
export default {
  name: "news",
  components: {
    Language,
    HeaderNav,
    Tooters,
	topsa
  },
  data() {
    return {
      targetboxOne: 0,
      targetboxTwo: 0,
      targetboxTher: 0,
      targetboxFover: 0,
	  scolle:0,
      lsitImg: [
        {
          img: require("../assets/images/enm.png"),
          name: "message.ProductPortfController",
        },
        {
          img: require("../assets/images/SDleixing.png"),
          name: "message.MediaSD",
        },
        {
          img: require("../assets/images/DSLR.png"),
          name: "message.Storage_title",
        },
        {
          img: require("../assets/images/legacy.png"),
          name: "message.cardHerder",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        let targetboxOne = document.getElementById("targetboxOne");
        let targetboxTwo = document.getElementById("targetboxTwo");
        let targetboxTher = document.getElementById("targetboxTher");
        let targetboxFover = document.getElementById("targetboxFover");
        this.targetboxOne = targetboxOne.offsetTop;
        this.targetboxTwo = targetboxTwo.offsetTop;
        this.targetboxTher = targetboxTher.offsetTop;
        this.targetboxFover = targetboxFover.offsetTop;
        console.log(
          targetboxOne.offsetTop,
          targetboxTwo.offsetTop,
          targetboxTher.offsetTop,
          targetboxFover.offsetTop
        );
      });
    });
	 window.addEventListener('scroll',this.handleScroll,true)
  },
  methods: {
	  handleScroll(){
		let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
		console.log(scrollTop)
		this.scolle=scrollTop
	  },
    clickToHove(index) {
      if (index === 0) {
		  let skw=this.targetboxOne
        document.body.scrollTop = document.documentElement.scrollTop = parseInt(
          skw
        );
      }
      if (index === 1) {
		  let skw=this.targetboxTwo
        document.body.scrollTop = document.documentElement.scrollTop = parseInt(
          skw
        );
        //  document.body.scrollTop = this.targetboxTwo
		console.log(skw)
      }
      if (index === 2) {
		  let skw=this.targetboxTher
        document.body.scrollTop = document.documentElement.scrollTop = parseInt(
          skw
        );
        //  document.body.scrollTop = this.targetboxTher
		console.log(skw)
      }
      if (index === 3) {
		  let skw=this.targetboxFover
		  
        document.body.scrollTop = document.documentElement.scrollTop = parseInt(
          skw
        );
        //  document.body.scrollTop = this.targetboxFover
		console.log(skw)
      }
    },
    todetails() {
      this.$router.push({ path: "/newsdetail" });
    },
  },
};
</script>

<style lang="scss" scoped>
.news_bg {
  width: 100%;
  position: relative;
  img {
	  display: block;
    width: 100%;
  }
  .news_bg_adlist {
    width: 14.55rem;
    height: 100%;
    padding-left: 0.6rem;
    line-height: 7rem;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0;
    top: 0;
    margin: auto;
    z-index: 9999;
    font-size: 0.7rem;
    font-weight: 600;
    color: #f6f6f6;
    z-index: 888;
  }
}
.lagins {
  width: 14.55rem;
  margin-top: 0.5rem;
  position: absolute;
  right: 2.4rem;
  top: 0;
  z-index: 9999 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header_left {
    display: flex;
    align-items: center;
    .lagins_Language {
		cursor: pointer;
      width: 0.6rem;
      height: 0.6rem;
    }
  }
}
.project_cen_top {
  height: 5.2rem;
  padding-top: 0.8rem;
  background: #f6f6f6;
  .project_top_centers {
    width: 14.55rem;
    margin: 0 auto;

    .project_top_centers_top {
      font-size: 0.36rem;
      font-weight: 600;
      color: #072754;
      text-align: center;
      .project_top_centers_border {
        width: 0.57rem;
        height: 0.07rem;
        background: #6a79f7;
        margin: 0 auto;
        margin-top: 0.33rem;
      }
    }
    .project_top_centers_bottom {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 0.6rem;
      .project_top_li {
		  cursor: pointer;
        position: relative;
        font-size: 0.2rem;
        font-weight: 500;
        color: #ffffff;
		width: 3.23rem;
		height: 3.03rem;
		img{
			width: 100%;
			display: inline-block;
		}
        .project_top_li_text {
		  position: absolute;
		  width: 100%;
		  bottom: 0;
		  right: 0;
		  background: rgba($color: #000000, $alpha: 1);
		  border-bottom-left-radius: 0.05rem;
		  border-bottom-right-radius: 0.05rem;
		  opacity: 0.5;
		  margin: auto;
		  height: 0.83rem;
		  font-size: 0.2rem;
		  font-weight: 500;
		  color: #ffffff;
		  text-align: center;
		  display: flex;
		  align-items: center;
		  justify-content: center;
		  line-height: 0.3rem;
        }
		.project_top_li_text1{
			width: 3.2rem;
			right: 0.02rem;
		}
      }
      .project_top_li:hover .project_top_li_text {
        background: linear-gradient(#0c55c0, #072f86);
        opacity: 1;
      }
    }
  }
}
.spot{
	width: 0.17rem;
	height: 0.17rem;
	background-color: #002DD8;
	border-radius: 50%;
	position: relative;
	left: 3.21rem;
	bottom: 0.5rem;
}
.spot1{
	width: 0.25rem;
	height: 0.25rem;
	background-color: #F8BA46;
	border-radius: 50%;
	position: relative;
	left: 0;
	bottom: 0.4rem;
}
.spot2{
	width: 0.23rem;
	height: 0.23rem;
	background-color: #00D577;
	border-radius: 50%;
	position: relative;
	left: 5rem;
	bottom: .5rem;
}
.spot3{
	width: 0.28rem;
	height: 0.28rem;
	background-color: #66CBE5;
	border-radius: 50%;
	position: relative;
	left: 3.5rem;
	bottom: 0.4rem;
}
.project_center {
  width: 14.55rem;
  margin: 0 auto;
  padding-bottom: 0.7rem;
  .project_center_one {
    display: flex;
    // align-items: center;
    margin-top: 2rem;
	position: relative;
	.project_one_left{
		img{
			width: 6.39rem;
			height: 6.38rem;
		}
	}
    .project_one_right {
      margin-left: 1.48rem;
      .titles {
        font-size: 0.24rem;
        font-weight: 500;
        color: #072754;
		margin-bottom: 0.28rem;
        line-height: 0.58rem;
        .spans {
          width: 1.74rem;
          height: 0.02rem;
          background: #002dd8;
        }
      }
	  
      .p_text {
        font-size: 0.16rem;
        font-weight: 400;
        color: #072754;
        line-height: 0.24rem;
        opacity: 0.8;
        margin-bottom: 0.2rem;
      }
      .lsit_uls {
        margin-bottom: 0.27rem;
        .lsit_title {
          font-size: 0.18rem;
          font-weight: bold;
          color: #002dd8;
          line-height: 0.24rem;
          opacity: 0.8;
          display: flex;
          align-items: center;
          .lsit_title_ridon {
            display: inline-block;
            width: 0.07rem;
            height: 0.07rem;
            background: #002dd8;
            border-radius: 50%;
            margin-right: 0.12rem;
          }
        }
        .lsit_li {
          width: 5rem;
          // height: 62px;
          font-size: 0.16rem;
          font-weight: 400;
          color: #072754;
          line-height: 0.24rem;
		  margin-top: .05rem;
          opacity: 0.8;
        }
      }
    }
  }
  .project_center_one1{
	  animation: forward 1s;
  }
  @keyframes forward {
    0% {right: -6rem; }
    100% {right: 0;}
	}
	.project_center_two1{
		animation: marsg 1s;
	}
	@keyframes marsg {
	  0% {left: -6rem;}
	  100% {left: 0;}
	}
  .project_center_two {
	margin-top: 0.5rem;
    display: flex;
	position: relative;
    .project_center_two_left {
      // display: flex;
      // align-items: center;
      width: 7.28rem;
      .project_two_title {
        font-size: 0.24rem;
        font-weight: 500;
        color: #072754;
        line-height: 0.58rem;
		margin-bottom: 0.28rem;
        .project_two_apn {
          width: 1.74rem;
          height: 0.02rem;
          background: #f8ba46;
        }
      }
      .project_two_p {
        font-size: 0.16rem;
        font-weight: 400;
        color: #072754;
        line-height: 0.24rem;
        opacity: 0.8;
        margin-bottom: 0.3rem;
      }
      .project_uls {
        display: flex;
        flex-flow: row wrap;
		justify-content: flex-start;
        .project_two_ul {
          width: 3rem;
          margin-bottom: 0.28rem;
		  margin-right: 0.15rem;
          .project_two_li_header {
            font-size: 0.18rem;
            font-weight: bold;
            color: #f8ba46;
            line-height: 0.24rem;
            opacity: 0.8;
            .project_two_li_header_span {
              display: inline-block;
              width: 0.07rem;
              height: 0.07rem;
              background: #f8ba46;
              border-radius: 50%;
			  margin-right: 0.08rem;
            }
          }
          .project_two_li {
            width: 3rem;
            height: 0.62rem;
            font-size: 0.16rem;
            font-weight: 400;
            color: #072754;
            line-height: 0.24rem;
            opacity: 0.8;
			margin-top: .05rem;
          }
        }
      }
	  .project_uls1 {
	    display: flex;
	    flex-flow: column;
	  	justify-content: flex-start;
	    .project_two_ul {
	      margin-bottom: .28rem;
	      .project_two_li_header {
	        font-size: 0.18rem;
	        font-weight: bold;
	        color: #f8ba46;
	        line-height: 0.24rem;
	        opacity: 0.8;
	        .project_two_li_header_span {
	          display: inline-block;
	          width: 0.07rem;
	          height: 0.07rem;
	          background: #f8ba46;
	          border-radius: 50%;
			  margin-right: 0.08rem;
	        }
	      }
	      .project_two_li {
			margin-top: .05rem;
	        font-size: 0.16rem;
	        font-weight: 400;
	        color: #072754;
	        line-height: 0.24rem;
	        opacity: 0.8;
	      }
	    }
	  }
    }
    .project_center_two_right {
      margin-left: 1.5rem;
	  img{
		  width: 6.74rem;
		  height: 6.38rem;
	  }
    }
  }
}
</style>