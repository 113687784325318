<template>
  <div>
    <div class="news_bg">
      <div class="lagins">
        <div>
          <img src="../assets/images/logo.png" alt="" />
        </div>
        <div class="header_left">
          <HeaderNav></HeaderNav>
          <div class="lagins_Language">
            <Language style="width: 100%; height: 100%"></Language>
          </div>
        </div>
      </div>
      <img src="../assets/images/picnews.jpg" alt="" />
    </div>
    <nav class="newsdetail_nav">
      <div class="newsdetail_nav_center">
        <div class="newsdetail_nav_center_title">
          {{ detailObj.title }}
        </div>
        <div class="title_date">
          {{ this.formatDate(detailObj.updateDate) }}
        </div>
        <p class="p_center" v-html="detailObj.content"></p>
        <div class="solid"></div>
        <div class="texts" v-if="oneObg" @click="todetails(oneObg.id)">
          <span>{{ $t("message.Previous") }}：</span>{{ oneObg.title }}
        </div>
        <div class="texts" v-if="towObg" @click="todetails(towObg.id)">
          <span>{{ $t("message.Next") }}：</span>{{ towObg.title }}
        </div>
      </div>
	  <img class="imgss" src="../assets/images/ditu2.png" />
    </nav>
	<topsa></topsa>
    <Tooters></Tooters>
  </div>
</template>

<script>
import Language from "../components/language";
import HeaderNav from "../components/headernav";
import Tooters from "../components/footers";
import topsa from '../components/topsa.vue';
export default {
  name: "newsdetail",
  data() {
    return {
      id: this.$route.query.id,
      come: 0,
      detailObj: {},
      oneObg: {},
      towObg: {},
    };
  },
  components: {
    Language,
    HeaderNav,
    Tooters,
	topsa
  },
  created() {
    this.$bus.$on("languages", (v) => {
      this.detailsApi();
    });
  },
  beforDestroy() {
    this.$bus.$off("languages"); //当这个组件销毁的时候bus也跟着一起销毁
  },
  mounted() {
    this.detailsApi();
    this.newsList();
  },
  methods: {
    todetails(id){
      this.id = id;
      this.detailsApi();
	  this.newsList();
    },
    newsList() {
      this.$axios
        .get(this.$urls + "/news/list?come=0" + "&limit=100" + "&page=1")
        .then((response) => {
          let lsit = response.data.result;
          var num = 0;
          for (var i in lsit) {
            if (lsit[i].id == this.id) {
              num = i;
            }
          }

          this.oneObg = lsit[Number(num) - 1];
          this.towObg = lsit[Number(num) + 1];
		  
          console.log(num, "numnumnumnumnum");
          console.log(this.oneObg, "1111111111", this.towObg);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    detailsApi() {
      this.$axios
        .get(this.$urls + `/news/detail/${this.id}?come=` + this.come)
        .then((response) => {
          this.detailObj = response.data.result;
          console.log(response.data.result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate(date) {
      var date = new Date(date);
      var YY = date.getFullYear() + ".";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + ".";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // return YY + MM + DD + " " + hh + mm + ss;
      return YY + MM + DD;
    },
  },
};
</script>

<style lang="scss" scoped>
.news_bg {
  width: 100%;
  height: 7rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.lagins {
  width: 14.55rem;
  margin-top: 0.5rem;
  position: absolute;
  right: 2.4rem;
  top: 0;
  z-index: 9999 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header_left {
    display: flex;
    align-items: center;
    .lagins_Language {
		cursor: pointer;
      width: 0.6rem;
      height: 0.6rem;
    }
  }
}
.newsdetail_nav {
  width: 100%;
  background-color: #EDEDED;
  position: relative;
  overflow: hidden;
  .imgss{
  	  width: 100%;
  	  display: inline-block;
  	  position: absolute;
  	  bottom: 0;
  	  left: 0;
  	  z-index: 1;
  }
  .newsdetail_nav_center {
	position: relative;
	z-index: 9;
    width: 12rem;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 0.08rem;
    padding: 0.8rem 1.45rem;
    box-sizing: border-box;
    margin-bottom: 1.9rem;
    .newsdetail_nav_center_title {
      font-size: 0.24rem;
      font-weight: 600;
      color: #0e3899;
      line-height: 0.3rem;
    }
    .title_date {
      text-align: right;
      font-size: 0.16rem;
      font-weight: 500;
      color: #999999;
      line-height: 0.24rem;
      margin-top: 0.2rem;
      margin-bottom: 0.5rem;
    }
    .p_center {
      font-size: 0.14rem;
      font-weight: 400;
      color: #072754;
      line-height: 0.24rem;
      opacity: 0.8;
    }
    .solid {
      width: 100%;
      height: 0.02rem;
      background: #f1f1f1;
      margin-top: 2rem;
      margin-bottom: 0.34rem;
    }
    .texts {
      width: 100%;
      font-size: 0.14rem;
      font-weight: 600;
      color: #072754;
      line-height: 0.24rem;
      opacity: 0.8;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
	  span{
		  display: inline-block;
		  width: .8rem;
		  text-align: right;
	  }
    }
   .texts:hover {
      color:#0e3899;
    }
  }
}
.footers {
  margin: 0;
}
</style>