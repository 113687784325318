<template>
  <div class="body-content">
    <div>
      <el-carousel ref="carousel" indicator-position="none" :autoplay="autoplay" :interval="5000" @change="changeCampus">
        <el-carousel-item>
          <img style="width: 100%;display: block;" src="./img/pic.jpg" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img style="width: 100%;display: block;" src="./img/banner2.jpg" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img style="width: 100%;display: block;" src="./img/banner3.jpg" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img style="width: 100%;display: block;" src="./img/banner4.jpg" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: "",
      autoplay: true,
    };
  },
  props: {
    indexs: {
      type: Number, //指定传入的类型
      default: 0, //这样可以指定默认的值
    },
    pagePostion: {
      type: Number, //指定传入的类型
      default: 0, //这样可以指定默认的值
    },
    mouseActive: {
      type: Number, //指定传入的类型
      default: 0, //这样可以指定默认的值
    },
  },
  watch: {
    indexs: {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        this.$refs.carousel.setActiveItem(Number(val))
      },
      deep: true, //true 深度监听
    },
    mouseActive: {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        if(val === 1){
          this.autoplay = false
        }
        if(val === 2){
          this.autoplay = true
        }
        this.$emit("mouseActiveApi", 0);
      },
      deep: true, //true 深度监听
    },
    pagePostion: {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        // console.log(val, "当前页");
        if(val === 1){
          this.$refs.carousel.prev();
        }
        if(val === 2){
          this.$refs.carousel.next();
        }
        this.$emit("pagePostionApi", 0);
        this.$emit("bannerEmit", 1);
      },
      deep: true, //true 深度监听
    },
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    changeCampus(val){
      // let activeIndex = this.$refs.carousel.activeIndex
      // console.log(val)
      this.$emit("bannerEmit", val);
      // this.$router.push(this.imgs[activeIndex].link)
    },
  },
  components: {},
};
</script>
<style scoped>
/deep/ .el-carousel__arrow,.el-carousel__arrow--left{
	display: none;
}
/deep/ .el-carousel__container{
	height: 5.5rem;
}
</style>