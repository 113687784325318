<template>
  <div>
    <ul class="nav_ul">
      <li
        class="nav_li"
        v-for="(item, index) in navList"
        :key="index"
        @click="clickActive(index)"
        :style="index === active ?'color: #ffffff;font-weight: 600;' :''"
      >
        {{ $t(item.name) }}
        <div class="li_siled" v-if="index === active"></div>
        <div class="li_siledone" v-if="index !== active"></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "headernav",
  data() {
    return {
      active: 0,
      navList: [
        {
          name: "message.Home",
          path: "/index",
          id: 0,
        },
        {
          name: "message.News",
          path: "/news",
          id: 1,
        },
        {
          name: "message.Product",
          path: "/project",
          id: 2,
        },
        {
          name: "message.Cooperation",
          path: "/cooperation",
          id: 3,
        },
        {
          name: "message.Contactus",
          path: "/contactus",
          id: 4,
        },
      ],
    };
  },
  mounted() {
    var value = sessionStorage.getItem("keyactive");
    console.log(value);
    if (value) {
      this.active = Number(value);
    }
    this.navList.forEach((item) => {
      if (this.$router.history.current.fullPath === item.path) {
        this.active = item.id;
      }
    });
  },
  methods: {
    clickActive(index) {
      this.active = index;
      sessionStorage.setItem("keyactive", index);
      if (index === 0) {
        this.$router.push({ path: "/index" });
      } else if (index === 1) {
        this.$router.push({ path: "/news" });
      } else if (index === 2) {
        this.$router.push({ path: "/project" });
      } else if (index === 3) {
        this.$router.push({ path: "/cooperation" });
      } else if (index === 4) {
        this.$router.push({ path: "/contactus" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav_ul {
  font-size: 0.2rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.32rem;
  display: flex;

  .nav_li {
    padding: 0.18rem 0.4rem;
    box-sizing: border-box;
    position: relative;
	cursor: pointer;
    color: #d1deff;
    .li_siled {
      width: 0.54rem;
      height: 0.02rem;
      background: #ffffff;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    .li_siled:before {
      transform: scale(0);
      transition: all 0.3s;
    }
    .li_siledone:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0.54rem;
      height: 0.02rem;
      background: #ffffff;
      transform: scale(0);
      transition: all 0.3s;
    }
  }
  .nav_li:hover .li_siledone:before {
    transform: scale(1);
  }
}
</style>