<template>
  <div class="timeshafts">
    <div class="timeshafts_left">
      <div class="timeshafts_left_time">{{this.formatDate(datails.updateDate)}}</div>
      <div class="timeshafts_left_solid">
        <div class="timeshafts_left_redio">
          <div class="hen_solid"></div>
        </div>
      </div>
    </div>
    <div class="timeshafts_right">
      <img
        class="timeshafts_right_img"
        :src="datails.img"
        alt=""
      />
      <div class="timeshafts_rightsTwo">
        <div class="timeshafts_rightsTwo_title">
         {{datails.title}}
        </div>
        <div class="timeshafts_rightsTwo_p">
          <p v-html="datails.content"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "timeshaft",
  data() {
    return {
     
    };
  },
   props: {
    datails: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    // console.log(this.datails,"datailsdatailsdatailsdatails")
  },
  methods: {
    formatDate(date) {
      var date = new Date(date);
      var YY = date.getFullYear() + ".";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + ".";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // return YY + MM + DD + " " + hh + mm + ss;
      return YY + MM + DD;
    },
  },
};
</script>

<style lang="scss" scoped>

.timeshafts {
  width: 100%;
  display: flex;
  height: 2.85rem !important;
  .timeshafts_left {
    display: flex;
    .timeshafts_left_time {
		cursor: pointer;
      width: 1.25rem;
      height: 0.44rem;
      background: #f4f4f4;
      border-radius: 0.22rem;
      font-size: 0.16rem;
      font-weight: 500;
      color: #999999;
      line-height: 0.44rem;
      text-align: center;
      margin-right: 0.4rem;
      margin-top: 0.78rem;
    }
    .timeshafts_left_solid {
      width: 0.03rem;
      height: 100%;
      background: #f4f4f4;
      position: relative;
      .timeshafts_left_redio {
        position: absolute;
        left: -0.1rem;
        right: 0;
        top: 0.9rem;
        margin: auto;
        width: 0.18rem;
        height: 0.18rem;
        background: #f4f4f4;
        border: 0.03rem solid #00d577;
        border-radius: 50%;
        .hen_solid {
          width: 0.5rem;
          height: 0.03rem;
          background: #f4f4f4;
          position: absolute;
          left: 0.21rem;
          right: 0;
          top: 0.07rem;
        }
      }
    }
  }
  .timeshafts_right {
	  cursor: pointer;
    width: 11.75rem;
    height: 2.24rem;
    margin-left: 0.94rem;
    margin-top: 0.57rem;
    background: #f4f4f4;
    border-radius: 0.04rem;
    display: flex;
    align-items: center;
    .timeshafts_right_img {
      width: 4rem;
      height: 2.24rem;
      background: #c2c2c2;
      border-radius: 0.04rem 0px 0px 0.04rem;
    }
    .timeshafts_rightsTwo {
      width: 65%;
      padding: 0.3rem 0.85rem 0.38rem 0.48rem;
      box-sizing: border-box;
    }
    .timeshafts_rightsTwo_title {
      font-size: 0.18rem;
      font-weight: bold;
      // color: #0e3899;
      color: #062045;
      line-height: 0.3rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .timeshafts_rightsTwo_p {
      margin-top: 0.18rem;
      font-size: 0.14rem;
      font-weight: 400;
      color: #072754;
      line-height: 0.24rem;
      opacity: 0.8;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
  .timeshafts_right:hover .timeshafts_rightsTwo_title{
    color: #0e3899;
    
  }
  .timeshafts_right:hover{
     box-shadow: 0 0.01rem 0.04rem #888888
  }
 
}
</style>