<template>
  <div>
    <div class="news_bg">
      <div class="lagins">
        <div>
          <img src="../assets/images/logo.png" alt="" />
        </div>
        <div class="header_left">
          <HeaderNav></HeaderNav>
          <div class="lagins_Language">
            <Language style="width: 100%; height: 100%"></Language>
          </div>
        </div>
      </div>
      <img src="../assets/images/hezuo.jpg" alt="" />
    </div>
    <div class="cooper_banners_cen">
      <img class="imgsbg" src="../assets/images/busseing.jpg" alt="" />
      <div class="centers">
        <div class="cooper_title">
          {{ $t("message.BusinessDistributors") }}
          <div class="cooper_title_border"></div>
        </div>
        <div class="cooper_banners">
          <!-- <el-carousel :interval="4000" type="card" height="3rem">
            <el-carousel-item v-for="(item, index) in imgListOne" :key="index">
              <div class="imgbanner">
                <img :src="item.imgs" alt="" />
              </div>
            </el-carousel-item>
          </el-carousel> -->
          <div class="fot_swiper">
            <swiper :options="swiperOptions">
              <swiper-slide v-for="(item, index) in imgListOne" :key="index">
                <div class="sild">
                  <img :src="item.imgs" />
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </div>
        </div>

        <div class="cooper_title">
          {{ $t("message.IndustryAssociation") }}
          <div class="cooper_title_border"></div>
        </div>
      </div>
    </div>
    <div class="cooper_img">
      <img style="width: 100%;display: block;" src="../assets/images/industry.png" alt="" />
      <div class="cooper_center">
        <div
          class="cooper_img_list"
          v-for="(item, index) in imgListTwo"
          :key="index"
          :style="item.id == 99 ? 'width: 5.7rem' : ''"
        >
          <img :src="item.imgs" alt="" />
        </div>
      </div>
    </div>
	<topsa></topsa>
    <Tooters></Tooters>
  </div>
</template>

<script>
import Language from "../components/language";
import HeaderNav from "../components/headernav";
import Tooters from "../components/footers";
import Indetify from "../components/indetify";
import topsa from '../components/topsa.vue';
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "Cooperation",
  components: {
    Language,
    HeaderNav,
    Tooters,
    Indetify,
    Swiper,
    SwiperSlide,
	topsa
  },
  data() {
    return {
      imgListOne: [
        {
          imgs: require("../assets/images/10.png"),
        },
        {
          imgs: require("../assets/images/11.png"),
        },
        {
          imgs: require("../assets/images/12.png"),
        },
      ],
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 10,
        loop: true,
        observer: true,
        observeParents: true,
        setWrapperSize: true,
        autoplay:{
	      delay:4000,
	      disableOnInteraction:false
	    },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      imgListTwo: [
        {
          imgs: require("../assets/images/888.png"),
        },
        {
          imgs: require("../assets/images/22.png"),
        },
        {
          imgs: require("../assets/images/33.png"),
        },
        {
          imgs: require("../assets/images/44.png"),
        },
        {
          imgs: require("../assets/images/55.png"),
        },
        {
          imgs: require("../assets/images/66.png"),
        },
        {
          imgs: require("../assets/images/77.png"),
        },
        {
          imgs: require("../assets/images/88.png"),
        },
        {
          id: 99,
          imgs: require("../assets/images/99.png"),
        },
      ],
      Name: "message.Name",
      Title: "message.Title",
      Company: "message.Company",
      City: "message.City",
      State: "message.State",
      Country: "message.Country",
      email: "message.email",
      Briefdescription: "message.Briefdescription",
      Code: "message.Code",
      identifyCode: "",
      identifyCodes: "1234567890",
      formData: {
        city: "",
        company: "",
        content: "",
        country: "",
        email: "",
        name: "",
        state: "",
        title: "",
        remark: "",
      },
      codeNum: "",
    };
  },
  mounted() {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  methods: {
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
    onSubmit() {
      if (
        this.formData.name == "" ||
        this.formData.email == "" ||
        this.formData.remark == "" ||
        this.codeNum == ""
      ) {
        this.$message.error(this.$t("message.messages"));
        return;
      }
      if (this.codeNum == this.identifyCode) {
        this.formApi();
      } else {
        this.$message.error(this.$t('message.Verification'));
      }
    },
    formApi() {
      this.$axios
        .post(this.$urls + "/box/save", this.formData)
        .then((response) => {
          if (response.data.code === 200) {
            this.$message({
              message: "submit successfully",
              type: "success",
            });
            this.formData.city = "";
            this.formData.company = "";
            this.formData.content = "";
            this.formData.country = "";
            this.formData.email = "";
            this.formData.name = "";
            this.formData.state = "";
            this.formData.title = "";
            this.formData.remark = "";
            this.codeNum = "";
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-form-item__label {
  width: 4rem;
  height: 0.35rem;
  line-height: 0.35rem;
}
.news_bg {
  width: 100%;
  img {
    width: 100%;
	display: block;
  }
}
.lagins {
  width: 14.55rem;
  margin-top: 0.5rem;
  position: absolute;
  right: 2.4rem;
  top: 0;
  z-index: 9999 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header_left {
    display: flex;
    align-items: center;
    .lagins_Language {
		cursor: pointer;
      width: 0.6rem;
      height: 0.6rem;
    }
  }
}
.footers {
  margin: 0;
}

.cooper_banners_cen {
  width: 100%;
  height: 7rem;
  position: relative;
  .imgsbg {
    width: 100%;
	display: block;
  }
  .centers {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    .cooper_banners {
      width: 100%;
      padding-bottom: 0.6rem;
      box-sizing: border-box;
    }
    .cooper_title {
      width: 5.1rem;
      text-align: center;
      margin: 0 auto;
      font-size: 0.36rem;
      font-weight: 600;
      color: #072754;
      margin-top: 0.7rem;
      .cooper_title_border {
        width: 0.57rem;
        height: 0.07rem;
        background: #425ef5;
        margin: 0 auto;
        margin-top: 0.23rem;
      }
    }
  }
}
.cooper_img{
	width: 100%;
	position: relative;
	>img {
	  width: 100%;
	  display: block;
	  margin-top: -.15rem;
	}
	.cooper_center {
	  width: 14.55rem;
	  height: 100%;
	  box-sizing: border-box;
	  position: absolute;
	  z-index: 5;
	  top: 1rem;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  margin: auto;
	  display: flex;
	  flex-flow: wrap;
	  justify-content: space-between;
	  // align-items: center;
	
	  .cooper_img_list {
	    width: 2.7rem;
	    height: 2.7rem;
	    background: #ffffff;
		img{
			width: 100%;
			height: 100%;
		}
	  }
	}
}
.cooper_form {
  width: 100%;
  height: 9.64rem;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .cooper_form_cener {
    width: 14.55rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    .cooper_form_cener_title {
      font-size: 0.3rem;
      font-weight: 600;
      color: #072754;
      text-align: center;
      margin: 0 auto;
      margin-top: 0.94rem;
      .cooper_cener_title_boder {
        width: 0.57rem;
        height: 0.07rem;
        background: #6a79f7;
        margin: 0 auto;
        margin-top: 0.27rem;
      }
    }
    .fotm_p {
      font-size: 0.24rem;
      font-weight: 500;
      color: #072754;
      line-height: 0.58rem;
      text-align: center;
      margin-top: 0.53rem;
    }
    .forms {
      width: 14.46rem;
      margin: 0 auto;
      margin-top: 0.47rem;
      padding: 0.45rem;
      background: #ffffff;
      box-shadow: 0px 0.12rem 0.28rem 0.04rem rgba(7, 39, 135, 0.05);
      border-radius: 0.08rem;
      box-sizing: border-box;
      .el-input {
        font-size: 0.15rem;
        color: #606266;
      }
      .el-form-item__label {
        font-size: 0.15rem !important;
      }
    }
  }
}
.el-form {
  // margin-left: 1rem !important;
}
.imgbanner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 4.64rem;
  }
}
.imgbanner img {
  cursor: pointer;
  transition: all 0.6s;
}
.imgbanner img:hover {
  transform: scale(1.3);
}
.int_imgs {
  display: flex;
  align-items: center;
}
.list_top {
  display: flex;
  align-items: center;
  margin-left: 0.1rem;
}

.fot_swiper {
  margin-top: 0.59rem;
  padding: 0 2.37rem;
  box-sizing: border-box;
  position: relative;
  /deep/ .swiper-button-prev,
  .swiper-button-next {
    color: #00d577;
    font-weight: bold;
    outline: none;
  }
  /deep/ .swiper-button-prev {
    left: 1.8rem;
  }
  /deep/ .swiper-button-next {
    right: 1.8rem;
  }
  /deep/ .swiper-wrapper {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 2.32rem;
  }
  /deep/ .swiper-slide {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }
  .sild {
    width: 4.03rem;
    height: 1.8rem;
    background-color: #fff;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0.02rem 0.18rem 0.02rem rgba(175, 175, 175, 0.08);
    border-radius: 0.02rem;
    img {
      width: 4.03rem;
      display: block;
    }
  }
  /deep/ .swiper-slide-active,
  .swiper-slide-duplicate-prev {
    width: 4.03rem;
    height: 1.88rem;
  }
  /deep/ .swiper-slide-next {
    -webkit-transform: scale(1.2, 1.2);
    overflow: visible;
    .sild {
      width: 4.5rem;
      height: 1.88rem;
      img {
        width: 4.03rem;
        display: block;
      }
    }
  }
}
</style>