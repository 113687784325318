<template>
  <div class="language">
    {{ text }}
    <ul class="ul">
      <div class="ulposis"></div>
      <li
        class="lis"
        v-for="(item, index) in langList"
        :key="index"
        :style="item.id === id ? 'background: #00D577;color:#ffffff' : ''"
        @click="changLanguage(item)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "language",
  data() {
    return {
      langList: [
        {
          name: "中",
          id: "zh",
        },
        {
          name: "EN",
          id: "en",
        },
        {
          name: "日",
          id: "ja",
        },
      ],
      text: "EN",
      id: "en",
      isShow: false,
    };
  },
  mounted() {
    console.log(window.localStorage.getItem("language"));
    if (window.localStorage.getItem("language")) {
      this.langList.forEach((item) => {
        if (item.id === window.localStorage.getItem("language")) {
          this.id = item.id;
          this.text = item.name;
        }
      });

      this.$i18n.locale = window.localStorage.getItem("language");
    }
  },
  methods: {
    changLanguage(item) {
      this.text = item.name;
      this.id = item.id;
      this.$i18n.locale = item.id;
      window.localStorage.setItem("language", item.id);
      this.language()
      this.$bus.$emit("languages", item.id);
    },
    clickLook() {
      this.isShow = !this.isShow;
    },
    language(){
      var num = 0;
      if(this.id === "zh"){
        num = 2
      }
      if(this.id === "en"){
        num = 0
      }
      if(this.id === "ja"){
        num = 1
      }
       this.$axios
        .get(this.$urls + "/news/setLanguage?come=0" + "&language=" +num)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.language {
  width: 100%;
  height: 100%;
  background: #00d577;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.18rem;
  color: #ffffff;
  position: relative;
  .ul {
    display: none;
    position: absolute;
    top: 0.7rem;
    color: #5b6881;
    font-size: 0.18rem;
    .ulposis {
      position: absolute;
      top: -0.09rem;
      left: 0.25rem;
      width: 0;
      height: 0;
      border-left: 0.1rem solid transparent;
      border-right: 0.1rem solid transparent;
      border-bottom: 0.1rem solid #ffffff;
    }
    .lis {
      padding: 0.14rem 0.22rem;
      background: #ffffff;
      white-space: nowrap;
    }
    .lis:hover {
      background: rgba(0, 213, 119, 0.8);
      color: #ffffff;
    }
  }
}
.language:hover .ul {
  display: block;
}
</style>