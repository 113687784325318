<template>
  <div id="app" style="font-family: 'Montserrat-Regular_0';">
    <!-- <router-view /> -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "App",
};
</script>

<style>

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* custom */
a {
  color: #7e8c8d;
  text-decoration: none;
  -webkit-backface-visibility: hidden;
}

li {
  list-style: none;
}

::-webkit-scrollbar {
  width: 0.05rem;
  height: 0.05rem;
}

::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0.06rem;
}

::-webkit-scrollbar-thumb:vertical {
  height: 0.05rem;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 0.06rem;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 5px;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 0.06rem;
}

html,
body {
  width: 100%;
}

body {
  /*禁止文字自动调整大小(默认情况下旋转设备的时候文字大小会发生变化)*/
  -webkit-text-size-adjust: none;
  /*禁止浏览器默认点击元素出现高亮*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
}
/*自定义*/
/*清除浮动*/
svg {
  display: inline-flex;
}
.clear:after {
  content: "";
  display: block;
  clear: both;
}
.left {
  float: left;
}
.right {
  float: right;
}
a,
button,
input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
button,
input {
  -webkit-appearance: none;
  border-radius: 0;
}
a {
  text-decoration: none;
}
.el-form-item__label{
  font-size: 0.16rem !important;
}
.el-input__inner{
  height: 0.35rem !important;
  line-height: 0.35rem !important;
}
.el-button{
  padding: 0.1rem 0.3rem !important;
  font-size: 0.16rem !important;
  border-radius: 0.08rem !important;
  background: #00D577 !important;
}
.el-carousel__button{
      width: 0.1rem !important;
    height: 0.1rem !important;
    border-radius: 50%;
}
.el-carousel__indicator.is-active button {
   background: #00D577 !important;
}
.el-message{
  font-size: 0.2rem !important;
}
</style>
