<template>
  <div class="lsitbanner_center">
    <div class="lsitbanner_left" @click="todettails(objectList.id)">
      <div class="lsitbanner_left_imgs">
		  <img :src="objectList.img" alt="" />
	  </div>
      <div class="lsitbanner_left_header">
        <span class="spans">{{ objectList.title }}</span>
        <span class="datas">{{ formatDate(objectList.updateDate) }}</span>
      </div>
      <div class="lsitbanner_left_header_p" v-html="objectList.content">
        <!-- BayHub Technology Ltd is a global leader in the design, development and
        marketing of high-speed input and output controllers and EMMC flash
        memory controllers. Today, we are very pleased to announce that... -->
      </div>
    </div>
    <div class="lsitbanner_right">
      <div
        class="lsitbanner_right_li"
        v-for="(item, index) in obgsNues"
        :key="index" @click="roua(item)"
      >
        <img class="lsitbanner_right_li_img" :src="item.img" alt="" />
        <div class="lsitbanner_right_li_text">
          <div class="titles">{{ item.title }}</div>
          <div class="date">{{ formatDate(item.updateDate) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "listbanner",
  data() {
    return {
      come: 0,
      limit: 100,
      page: 1,
      active: '',
      obgsNues: [],
      objectList: {},
    };
  },
   created() {
    this.$bus.$on("languages", (v) => {
      this.newsList();
    });
  },
  beforDestroy() {
    this.$bus.$off("languages"); //当这个组件销毁的时候bus也跟着一起销毁
  },
  mounted() {
    this.newsList();
  },
  methods: {
    newsList() {
      this.$axios
        .get(
          this.$urls +
            "/news/list?come=" +
            this.come +
            "&limit=" +
            this.limit +
            "&page=" +
            this.page
        )
        .then((response) => {
          let objdata = response.data.result.splice(0, 4);
		  this.objectList=objdata[0]
		  this.obgsNues=objdata.splice(1,3)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 跳转去详情
    todettails(id) {
      this.$router.push({ path: "/newsdetail?id=" + id });
	  sessionStorage.setItem('keyactive',1);
	  
    },
	roua(row){
		this.$router.push({ path: "/newsdetail?id=" + row.id });
		sessionStorage.setItem('keyactive',1);
	},
    formatDate(date) {
      var date = new Date(date);
      var YY = date.getFullYear() + ".";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + ".";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // return YY + MM + DD + " " + hh + mm + ss;
      return YY + MM + DD;
    },
  },
};
</script>

<style lang="scss" scoped>
.lsitbanner_center {
  display: flex;
  align-items: center;
  height: 5.04rem;
  overflow: hidden;
  // background: #f6f6f6;
  .lsitbanner_left {
	  cursor: pointer;
    width: 8.25rem;
    height: 5.04rem;
    background: #f6f6f6;
	box-sizing: border-box;
    .lsitbanner_left_imgs {
      width: 100%;
      height: 3.44rem;
	  overflow: hidden;
	  img{
		  width: 100%;
		  display: block;
	  }
    }
    .lsitbanner_left_header {
      width: 100%;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.18rem;
      font-weight: 500;
      color: #072754;
      padding: 0 0.24rem;
      box-sizing: border-box;
      margin: 0.28rem 0;
      .spans {
        display: inline-block;
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .datas {
        white-space: nowrap;
        font-size: 0.14rem;
        font-weight: 500;
        color: #072754;
        opacity: 0.6;
      }
    }
    .lsitbanner_left_header_p {
      font-size: 0.14rem;
      font-weight: 400;
      color: #072754;
      line-height: 0.24rem;
      opacity: 0.8;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      padding: 0 0.24rem;
      //  padding-bottom: 20px;
    }
  }
  .lsitbanner_left:hover{
	  background: #ffffff;
	  border: 0.01rem solid #425ef5;
	  box-shadow: 0px 0.1rem 0.16rem 0.02rem rgba(183, 183, 183, 0.4);
  }
  .lsitbanner_right {
    width: 6.02rem;
    margin-left: 0.12rem;
    height: 5.04rem;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
    // overflow: scroll;
    .lsitbanner_right_li {
		cursor: pointer;
      padding: 0.1rem 0.12rem;
      //   padding: 10px 12px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      background: #f6f6f6;
      .lsitbanner_right_li_img {
        width: 2.4rem;
        height: 1.34rem;
      }
      .lsitbanner_right_li_text {
        width: 2.96rem;
        font-size: 0.18rem;
        font-weight: 500;
        color: #062045;
        line-height: 0.3rem;
        padding-left: 0.3rem;
        .titles{
          text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
        }
      }
      .date {
        font-size: 0.14rem;
        font-weight: 500;
        color: #868e9d;
        line-height: 0.58rem;
        opacity: 0.6;
      }
    }
    .lsitbanner_right_li:hover {
      background: #ffffff;
      border: 0.01rem solid #425ef5;
      box-shadow: 0px 0.1rem 0.16rem 0.02rem rgba(183, 183, 183, 0.4);
    }
  }
}
</style>