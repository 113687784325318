const en = {
    message: {
        Home: 'Home',
        News: "News",
        Product: "Product",
        Cooperation: "Partners",
        Contactus: "Contact us",
        SuperPerformance: "Superb Performance",
        SuperPerformanceText: "Connectivity Hub processor high performance technology for image/video embedded application",
        UltraPowerSavings: "Ultra Power Savings",
        UltraPowerSavingsText: "Power optimizer that creates deep power savings through SerDes (Serializer/Deserializer) high speed I/O connectivity controller technology to interface eMMC , SD and Compact Flash cards for NBPC &Tablet application",
        HighQuality: "High Quality",
        HighQualityText: "Design and test methodologies achieve the high-quality products with extra low DPPM level for consumer and industry applications",
        GreaInnovation: "Great Innovation",
        GreaInnovationText: "Creatively innovative products, making applications' solutions easy to use and cost effective",
        CompanyProfile: 'Company Profile',
        BayHubCompany: "BayHub Company",
        BayHubtextOne: "BayHub Technology Ltd develops and markets the Hub Processor products with high speed SERDES (Serializer/Deserializer) connectivity technology for mobile devices and embedded applications",
        BayHubtextTwo: "With offices worldwide , the company is staffed by a team of industry veterans from Ricoh , NEC with a successful track record and proven expertise in design, marketing , and operations.",
        BayHubtextTher: "BayHub‘s extensive list of customers includes most of the world’s leading electronics manufacturers of Computer , Consumer and Industrial products.",
        ProductPortfolio: "Product Portfolio",
		
        EmccTitle: "eMMC Storage Device Controller",
        EmccText: "eMMC storage drive is prefect for small size solid state storage drive (16GB ~ 128GB) compared to SSD and HDD. This is good for Education NBPC, Cloud NBPC, ChromeBook , and IOT application. Offer a full product line with different host interfaces including SATA and PCIe to support eMMC 5.x HS200 and HS400 spec .",
        EmccTextOne: "This product family is well approved to be used as main bootable drive in many market systems.",
        TextOne: "SATA2 to eMMC5.0 bridge",
        TextTwo: "eMMC bootable ; Low power consumption",
        TextTher: "SATA Devslp",
		
        ModernOne: "PCIe to eMMC5.0 HS200 bridge",
        ModernTwo: "eMMC bootable ; Low power consumption",
        ModernTher: "Modern Standby",
		
        PcienOne: "PCIe to eMMC5.1 HS400 bridge",
        PcienTwo: "eMMC bootable ; Low power consumption",
        PcienTher: "Modern Standby",
		
        MediaSD: "SD Media Card Reader",
        MediaSDtext: "BayHub product family offers PCIe host interface controllers to support SD card spec from SD2.x , SD3.x , and SD4.x to latest SD7.x with advanced “Modern Standby” low power feature. Our product family has been tested and proven in the marketplace since year 2000 with worldwide SD card compatibility proven record. Intel and AMD qualify our parts to put on platform component list.",
        SD_j_one: "PCIe to SD3 bridge",
        SD_j_two: "Low power consumption",
        SD_j_ther: "Modern Standby",
		
        SD_f_one: "PCIe to SD4 bridge",
        SD_f_two: "Low power consumption",
        SD_f_ther: "Modern Standby",
		
        SD_lv_one: "PCIe to SD3 bridge",
        SD_lv_two: "Low power consumption",
        SD_lv_ther: "Modern Standby",
		
        SD_g_one: "PCIe to SD7 bridge",
        SD_g_two: "Low power consumption",
        SD_g_ther: "Modern Standby",
		
        SD_bh_one: "SD3 native interface companion",
        SD_bh_two: "Enable native SD3 interface",
        SD_bh_ther: "Low power consumption",
		
        Storage_title: "DSLR Media Card Hub Processor",
        StorageTextTwo: "BayHub product family supports those DSLR requirements to get the highest performance read & write to the media cards ,  multiple type medica cards  and dual slots support. Addition to that, the product family supports the latest power management technology to save power consumption under embedded OS environment.",
        StorageTextOne: "Latest DSLR and mirrorless cameras require the ultra-high resolution, the higher continuous shooting and high resolution video recording to capture the great and impressive scenes of the nature, sports and others.",
        Storage_f_one: "PCIe to SD4 Card Hub bridge",
        Storage_f_two: "Low power consumption",
        Storage_sd_one: "PCIe to SD4 Card Hub bridge",
        Storage_sd_two: "Dual SD host interface",
        Storage_sd_ther: "Low power consumption",
		
        cardHerder: "Legacy Controller",
        card_text: "In such legacy product line family, they have been in production for more than 15 years. They are only few existing IC which can be found in the world. This product family is well approved to be used as main bootable drive in many market systems.",
        card_tj_one: "PCIe to CardBus bridge",
        card_tj_two: "PC Card Standard Release 8.1 compliant",
        card_tj_ther: "CompactFlash Specification 4.1, UDMA Mode 4,5,6, PIO Mode6",
        card_tj_forv: "Low power consumption",
        card_num_one: "CardBus Power switch",
        card_num_two: "Compatible with Industry Standard CardBus",
		card_num_two1:'Controller technology to interface',
        card_cr_one: "Single chip USB CCID compliant",
        card_cr_two: "Supports ISO 7816-3 asynchronous and 7816-10",
        card_cr_ther: "synchronous smart cards ",
        card_cr_forv: "Provides USB smart cards (eGate) connectivity",
        card_cr_five: "Supports T=0 and T=1 smart card transmissions",
        NewsHeader: "News Center",
        NewsCenter: "News Center",
		
        ProductPortfController: "eMMC Storage Device Controller",
        ProductPortDevice: "SD Media Card Reader",
        ProductPortReaderr: "DSLR Media Card Hub Processor",
        ProductPortfContLegacy: "Legacy Controller",
        Product_PortfController_text_one: "eMMC storage drive is prefect for small size solid state storage drive (16GB ~ 128GB) compared to SSD and HDD. This is good for Education NBPC, Cloud NBPC, Chromebook , and IOT application. Offer a full product line with different host interfaces including SATA and PCIe to support eMMC 5.x HS200 and HS400 spec .",
        Product_PortfController_text_two: "This product family is well approved to be used as main bootable drive in many market systems.",
        Product_wr_one: "SATA2 to eMMC5.0 HS-200 bridge",
        Product_wr_two: "emmC bootable ; Low power consumption",
        Product_wr_ther: "SATA Devslp",
        Product_fj_one: "PCIe to eMMC5.0 HS200 bridge",
        Product_fj_two: "eMMC bootable ; Low power consumption",
        Product_fj_ther: "Modern Standby",
        Product_bb_one: "PCIe to eMMC5.1 HS400 bridge",
        Product_bb_two: "eMMC bootable ; Low power consumption ",
        Product_bb_ther: "Modern Standby",
        ProductPortDevice_text: "BayHub product family offers PCIe host interface controllers to support SD card spec from SD2.x , SD3.x , and SD4.x to latest SD7.x with advanced “Modern Standby” low power feature. Our product family has been tested and proven in the marketplace since year 2000 with worldwide SD card compatibility proven record. Intel and AMD qualify our parts to put on platform component list.",
        Product_dfj_one: "PCIe to SD3 bridge",
        Product_dfj_two: "Low power consumption",
        Product_dfj_ther: "Modern Standby",
        Product_bfj_one: "PCIe to SD4 bridge",
        Product_bfj_two: "Low power consumption ",
        Product_bfj_ther: "Modern Standby",
        Product_lv_one: "PCIe to SD3 bridge",
        Product_lv_two: "Low power consumption ",
        Product_lv_ther: "Modern Standby",
        Product_gg_one: "PCIe to SD7 bridge",
        Product_gg_two: "Low power consumption",
        Product_gg_ther: "Modern Standby",
        Product_bh2_one: "SD3 native interface companion",
        Product_bh2_two: "Enable native SD3 interface",
        Product_bh2_ther: "Low power consumption",
        ProductCardHub: "DSLR Media Card Hub Processor",
        ProductCardHubText: "Latest DSLR and mirrorless cameras require the ultra-high resolution, the higher continuous shooting and high-resolution video recording to capture the great and impressive scenes of the nature, sports and others.",
        ProductCardHubTextOne: "BayHub product family supports those DSLR requirements to get the highest performance read & write to the media cards ,  multiple type medica cards  and dual slots support. Addition to that, the product family supports the latest power management technology to save power consumption under embedded OS environment.",
        CardHub_fj_one: "PCIe to SD4 Card Hub bridge",
        CardHub_fj_two: "Low power consumption",
        CardHub_sd_one: "PCIe to SD4 Card Hub bridge",
        CardHub_sd_two: "Dual SD host interface",
        CardHub_sd_ther: "Low power consumption",
        ProductLegacy: "Legacy Controller",
        ProductLegacyText: "In such legacy product line family, they have been in production for more than 15 years. They are only existing IC which can be found in the world. This product family is well approved to be used as main bootable drive in many market systems.",
        ProductLegacy_tj_one: "PCIe to CardBus bridge",
        ProductLegacy_tj_two: "PC Card Standard Release 8.1 compliant",
        ProductLegacy_tj_ther: "CompactFlash Specification 4.1, UDMA Mode 4, 5, 6, and PIO Mode6",
        ProductLegacy_tj_fove: "Low power consumption",
        ProductLegacy_oz_one: "CardBus Power switch",
        ProductLegacy_oz_two: "Power switch   Compatible with Industry Standard CardBus Controller technology to interface",
        ProductLegacy_cr_one: "Single chip USB CCID compliant",
        ProductLegacy_cr_two: "Supports ISO 7816-3 asynchronous and 7816-10",
        ProductLegacy_cr_ther: "synchronous smart cards",
        ProductLegacy_cr_fove: "Provides USB smart cards (eGate) connectivity",
        ProductLegacy_cr_fie: "Supports T=0 and T=1 smart card transmission",
        BusinessDistributors: "Business Distributors",
        IndustryAssociation: "Industry Partners",
        BayHubWorldwide: "BayHub Worldwide",
        BayHubWorldwidetext: "In order to process your inquiry, please complete the following.",
        Name: "Name",
        Title: "Title",
        Company: "Company",
        City: "City",
        State: "State/Province",
        Country: "Country",
        email: "E-mail",
        Briefdescription: "Description",
        Code: "Code",
        Submit: "Submit",
        messages:"Veuillez améliorer l’information",
		Verification:'Verification code error',
        ContactUsto:"Contact us",
        OfficeLocation:"Office Location",
        positionListtitle:"Suzhou ,China",
        positionListpositiomtext:"#209, Zhuyuan Road, GaoXin District, Suzhou, China",
        positionListtitleOne:"Wuhan, China",
        positionListpositiomOnetext:"Floor 1, Building A6, Wuhan Best City, No.9 Huacheng Avenue, The East Lake High-tech Development Zone, Wuhan",
        positionListtitleTwo:"Hong Kong,China",
        positionListpositiomTwotext:"Hong Kong ,China RM 19C Lockhart CTR 301-307 Lockhart RD , Wan Chai HK",
        positionListtitlether:"Taiwan,China",
        positionListpositiomthertext:"8F., No.160, Sec. 2, Nanjing E. Rd., Taipei City 104, Taiwan",
        positionListtitlefove:"California, USA",
        positionListpositiomfovetext:"5201 Great America Pkwy., Suite 320, Santa Clara , CA 95054",
        positionListtitlefive:"Tokyo, Japan",
        positionListpositiomfivetext:"Postal Code 105-0001 Tornanomon , Minato-ku , Tokyo 3-14-9-309 Japan",
        SalesDistributor:"Sales Distributor",
        salestitleone:"Promate Electronic Co., LTD. (Taiwan)",
        salesposition:"4F 32, SEC. 1 Huan Shan RD., Neihu , Taipei 114 Taiwan , R.O.C. ",
        salestitletwo:"Promate Electronic Co., LTD. (Taiwan)",
        salespositiontwo: "4F 32, SEC. 1 Huan Shan RD., Neihu , Taipei 114 Taiwan , R.O.C.",
        salestitlether:"Promate Electronic (Shenzhen) Co., Ltd.",
        salespositionther:"Room 1409-1416, 14/F, FuChun-DongFang Building, No. 7006 Shennan Road,    Shenzhen, China",
        salestitlefover:"KOREA MIKASA CORPORATION (Korea)",
        salespositionfover:"RM No. 608, City Airport Logis & Travel Building,87-22 Teheran-Ro,Kangnam-Gu,Seoul,Korea,06164",
        salestitlefive:"Mikasa Shoji Co., Ltd.(Japan)",
        salespositionfive:"2-4-12 Shima-machi Osaka City Osaka Japan  540-0034",
		salesphonefive:'+81-6-6946-3737',
        salestitlesex:"SANSHIN ELECTRONICS CO., LTD. (Japan)",
        salespositionsex:"4-4-12, SHIBA MINATO-KU​, TOKYO, 108-0014 Japan",
		salesphone:'+81-3-5484-7395',
        salestitlesens:"Lacewood International Corp.- ( 深圳、台湾)",
        salespositionsens:"(China)Room 401-3 East, CEC Information Building, No.1 Xin Wen Road, ShenZhen, P.R.C.",
        Employment:"Employment",
        Employmenttext:"BayHub Technology Ltd develops and markets the Hub Processor products with high speed SERDES (Serializer/Deserializer) connectivity technology for mobile devices and embedded applications",
        JoinUs:"Join us",
        JoinUstext:"Place: A61 Floor, Wuhan Software New City, No. 9, Huacheng Avenue, East Lake High-tech Development Zone, Wuhan ",
        JoinUstextOne:"Email: cindy.yang@bayhubtech.com lily.yang@bayhubtech.com  ",
        JoinUstextTwo:"Contact information: 027-87538577 135451295687 13788040147",
        Jobdescription:"Job description",
        ContactUsToPosition:"A61 Floor, Wuhan Software New City, No. 9, Huacheng Avenue,East Lake High-tech Development Zone, Wuhan",
        BayhubName:"Bayhub Technology Co., Ltd",
		
		suzhou:'Suzhou: No.209, Zhuyuan Road, high tech Zone, Suzhou',
		hongkong:'Hong Kong: Room 301-307, 19C, Lockhart Center',
		wuhan:'Wuhan: Floor 1, Building A6, Wuhan Best City, No.9 Huacheng Avenue, The East Lake High-tech Development Zone, Wuhan',
		taiwan:'Taiwan: NO.160, 8th floor. 2 Nanjing East Road, Taipei, Taiwan 104',
		readmore:'Read More',
        Previous:"Previous",
        Next:"Next",
		banq:'Copyright @2013 BayHub all rights reserved.',
        youxiangs:"email",
        webname:"corporate web sites",
        jianlis:"Resume sent to",
    }
}

const zh = {
    message: {
        Home: '首页',
        News: "新闻",
        Product: "产品",
        Cooperation: "合作伙伴",
        Contactus: "联系我们",
        SuperPerformance: "超性能",
        SuperPerformanceText: "拥有高性能存储卡读写处理器技术，用于图像/视频嵌入式应用",
        UltraPowerSavings: "极省电",
        UltraPowerSavingsText: "功率优化器通过SerDes（Serializer / De-serializer）高速I / O连接控制器技术，连接NBPC和平板电脑的eMMC卡，SD卡和Compact Flash卡，可节省大量电能。",
        HighQuality: "高品质",
        HighQualityText: "设计和测试的方法非常成熟，可为消费者和工业应用提供具有极低DPPM水平的高质量产品。",
        GreaInnovation: "大创新",
        GreaInnovationText: "非常具有创造力的产品，使系统解决方案易于实现、使用且具有可观的成本优势。",
        CompanyProfile: '公司简介',
        BayHubCompany: "倍昊电子科技有限公司",
        BayHubtextOne: "倍昊电子科技有限公司开发和销售存储卡读写处理器产品，为移动设备和嵌入式应用提供高速SERDES（Serializer/Deserializer）连接技术。",
        BayHubtextTwo: "公司在全球各地设有办事处，员工由来自理光、NEC等行业资深人士组成，在设计、营销和运营方面拥有成熟的专业知识和非常成功的经验。",
        BayHubtextTher: "倍昊的客户群体包括大多数世界领先的计算机，消费品和工业产品的电子制造商。",
        ProductPortfolio: "产品组合",
        EmccTitle: "eMMC存储设备控制器",
        EmccText: "与SSD和HDD相比，eMMC存储驱动器是小型固态存储驱动器（16GB〜128GB）的最佳选择。这对Education NBPC，Cloud NBPC，Chromebook和IOT应用非常有用。提供不同主机接口的完整产品线，包括SATA和PCIe，以支持eMMC 5.x HS200和HS400规范。",
        EmccTextOne: "该系列产品被广泛应用于许多市场系统的主引导驱动器。",
        TextOne: "SATA到eMMC存储控制器",
        TextTwo: "支持从eMMC作为系统盘启动系统；低功耗",
        TextTher: "支持SATA 休眠模式",
        ModernOne: "PCIe到eMMC5.0 HS200存储控制器",
        ModernTwo: "支持从eMMC作为系统盘启动系统；低功耗",
        ModernTher: "支持Modern Standby",
        PcienOne: "PCIe到eMMC5.1 HS400存储控制器",
        PcienTwo: "支持从eMMC作为系统盘启动系统；低功耗",
        PcienTher: "支持Modern Standby",
        MediaSD: "SD读卡器",
        MediaSDtext: "该系列产品提供PCIe主机接口控制器，以支持SD卡规格（从SD2.x，SD3.x和SD4.x到最新的SD7.x），具有先进的“Modern Standby”低功耗功能。自2000年以来，我们的产品系列已经在市场上经过测试和验证，可以兼容全球范围内的SD卡。英特尔和AMD将我们的产品列入平台组件清单。",
        SD_j_one: "PCIe到SD3存储控制器",
        SD_j_two: "低功耗",
        SD_j_ther: "支持Modern Standby",
        SD_f_one: "PCIe到SD4存储控制器",
        SD_f_two: "低功耗",
        SD_f_ther: "支持Modern Standby",
		SD_lv_two:'低功耗',
		SD_lv_ther:'支持Modern Standby',
        SD_lv_one: "PCIe到SD3存储控制器",
        SD_lc_two: "低能耗",
        SD_lc_ther: "支持Modern Standby",
        SD_g_one: "PCIe到SD7存储控制器",
        SD_g_two: "低功耗",
        SD_g_ther: "支持Modern Standby",
        SD_bh_one: "SD3本机接口伴侣",
        SD_bh_two: "启用本机SD3接口",
        SD_bh_ther: "低功耗",
        Storage_title: "高端相机存储处理器",
        StorageTextOne: "最新的单反和无反光镜相机要求超高分辨率、更高的连续拍摄和高分辨率的视频录制，以捕捉大自然、运动等伟大而令人印象深刻的场景。",
        StorageTextTwo: "该系列产品支持单反相机的需求，实现了对内存卡，多种类型存储卡的最高性能读写。除此之外，该产品系列还支持最新的电源管理技术，以节省嵌入式操作系统环境下的功耗。",
        Storage_f_one: "PCIe到SD4存储控制器",
        Storage_f_two: "低功耗",
        Storage_sd_one: "PCIe到SD4存储控制器",
        Storage_sd_two: "支持2个SD卡插槽",
        Storage_sd_ther: "低功耗",
        cardHerder: "经典类控制器",
        card_text: "在此类传统产品系列中，它们已经投入生产超过15年。Bayhub是世界上少数能够提供此类芯片的供货商。该产品系列被广泛应用于许多市场系统的主引导驱动器。",
        card_tj_one: "PCIe到CardBus存储控制器",
        card_tj_two: "兼容PC卡标准版本8.1",
        card_tj_ther: "CompactFlash规范4.1，支持UDMA模式4、5、6和PIO模式6",
        card_tj_forv: "低功耗",
        card_num_one: "CardBus电源开关",
        card_num_two: "为PCMCIA/CardBus卡供电的电源开关芯片",
		card_num_two1:'兼容工业标准的CardBus控制器技术接口',
        card_cr_one: "符合USB CCID协议",
        card_cr_two: "支持ISO 7816-3异步和7816-10 同步智能卡",
        card_cr_ther: "提供USB智能卡（eGate）连接接口",
        card_cr_forv: "支持T = 0和T = 1智能卡传输",
        card_cr_five: "自动检测和操作EGAT卡",
		
		
        NewsHeader: "新闻中心",
        NewsCenter: "新闻中心",
        ProductPortfController: "eMMC存储设备控制器",
        ProductPortDevice: "SD媒体读卡器",
        ProductPortReaderr: "单反媒体卡集线器处理器",
        ProductPortfContLegacy: "液面控制器",
        Product_PortfController_text_one: "与SSD和HDD相比，eMMC存储驱动器是小型固态存储驱动器(16GB ~ 128GB)的最佳选择。这对教育NBPC、云NBPC、Chromebook和物联网应用都有好处。提供不同主机接口的完整产品线，包括SATA和PCIe，以支持eMMC 5。x HS200和HS400规格。",
        Product_PortfController_text_two: "该产品系列被广泛应用于许多市场系统的主引导驱动器。",
        Product_wr_one: "SATA2到eMMC5.0 HS-200桥架",
        Product_wr_two: "emmC引导;低功耗",
        Product_wr_ther: "SATA 开发",
        Product_fj_one: "PCIe到eMMC5.0 HS200桥架",
        Product_fj_two: "emmC引导;低功耗",
        Product_fj_ther: "现代备用",
        Product_bb_one: "PCIe到eMMC5.1 HS400桥",
        Product_bb_two: "emmC引导;低功耗",
        Product_bb_ther: "现代备用",
        ProductPortDevice_text: "BayHub产品系列提供PCIe主机接口控制器，从SD2支持SD卡规格。x, SD3。x和SD4。x到最新的SD7。x具有先进的“现代待机”低功耗功能。我们的产品系列自2000年以来在市场上经过了测试和验证，并在全球范围内的SD卡兼容性得到了证实。英特尔和AMD将我们的零部件列入平台组件清单。",
        Product_dfj_one: "PCIe到SD3桥接",
        Product_dfj_two: "低功耗",
        Product_dfj_ther: "Modern Standby",
        Product_bfj_one: "PCIe到SD4桥接",
        Product_bfj_two: "低功耗",
        Product_bfj_ther: "Modern Standby",
        Product_lv_one: "PCIe到SD4桥接",
        Product_lv_two: "低功耗",
        Product_lv_ther: "Modern Standby",
        Product_gg_one: "PCIe到SD7桥接器",
        Product_gg_two: "低功耗",
        Product_gg_ther: "Modern Standby",
        Product_bh2_one: "配套的SD3本地接口",
        Product_bh2_two: "启用本地SD3接口",
        Product_bh2_ther: "低功耗；低电耗",
        ProductCardHub: "单反媒体卡集线器处理器",
        ProductCardHubText: "最新的单反和无反光镜相机要求超高的分辨率，更高的连续拍摄和高分辨率的视频录制，捕捉大自然、运动等伟大而令人印象深刻的场景。",
        ProductCardHubTextOne: "BayHub产品家族支持这些DSLR需求，以获得最高性能的读写媒体卡，多类型的医疗卡和双插槽支持。此外，该产品系列支持最新的电源管理技术，在嵌入式操作系统环境下节省功耗。",
        CardHub_fj_one: "PCIe到SD4卡Hub桥",
        CardHub_fj_two: "低功耗",
        CardHub_sd_one: "PCIe到SD4卡Hub桥",
        CardHub_sd_two: "双SD主机接口",
        CardHub_sd_ther: "低功耗",
        ProductLegacy: "传统控制器",
        ProductLegacyText: "在这样的传统产品线家族，他们已经在生产超过15年。他们只是现存的IC，可以在世界上找到。该产品系列被广泛应用于许多市场系统的主引导驱动器。",
        ProductLegacy_tj_one: "PCIe到CardBus桥接器",
        ProductLegacy_tj_two: "PC卡标准版本8.1兼容",
        ProductLegacy_tj_ther: "CompactFlash Specification 4.1, UDMA Mode 4, 5, 6, and PIO Mode6",
        ProductLegacy_tj_fove: "低功耗",
        ProductLegacy_oz_one: "卡总线电源开关",
        ProductLegacy_oz_two: "电源开关与工业标准CardBus控制器技术相兼容",
        ProductLegacy_cr_one: "单片USB CCID兼容",
        ProductLegacy_cr_two: "支持ISO 7816-3异步和7816-10",
        ProductLegacy_cr_ther: "同步智能卡",
        ProductLegacy_cr_fove: "提供USB智能卡(gate)连接",
        ProductLegacy_cr_fie: "支持T=0和T=1智能卡传输",
        BusinessDistributors: "业务分销商",
        IndustryAssociation: "行业协会",
        BayHubWorldwide: "倍昊全球",
        BayHubWorldwidetext: "为了处理您的咨询，请完成以下步骤。",
        Name: "名称",
        Title: "职务",
        Company: "公司",
        City: "城市",
        State: "省/州",
        Country: "国家/地区",
        email: "电子邮件",
        Briefdescription: "简短说明",
        Code: "验证码",
        Submit: "提交",
        messages:"请完善信息",
		Verification:'验证码错误',
        ContactUsto:"联系我们",
        OfficeLocation:"办公地址",
        positionListtitle:"苏州，中国",
        positionListpositiomtext:"苏州高新区竹园路209号",
        positionListtitleOne:"武汉，中国",
        positionListpositiomOnetext:"湖北省武汉市东湖新技术开发区花城大道9号武汉软件新城A6栋1楼",
        positionListtitleTwo:"香港，中国",
        positionListpositiomTwotext:"中国香港湾仔骆克道19号洛克中心CTR 301-307",
        positionListtitlether:"台湾，中国",
        positionListpositiomthertext:"台北市104号南京东路二段160号8楼",
        positionListtitlefove:"加利福尼亚州，美国",
        positionListpositiomfovetext:"5201 Great America Pkwy，Suite 320，Santa Clara，CA 95054",
        positionListtitlefive:"日本，东京",
        positionListpositiomfivetext:"Postal Code 105-0001 Tornanomon , Minato-ku , Tokyo 3-14-9-309 Japan",
        SalesDistributor:"销售代理商",
        salestitleone:"丰艺电子有限公司（台湾, 中国）",
        salesposition:"SEC 4F 32台湾台北市内湖市环山路1号114",
        salestitletwo:"丰艺电子有限公司（台湾, 中国）",
        salespositiontwo: "SEC 4F 32台湾台北市内湖市环山路1号114",
        salestitlether:"嘉合丰电子（深圳）有限公司",
        salespositionther:"深圳市福田区深南大道7006号富春东方大厦14楼1409-1416室",
        salestitlefover:"KOREA MIKASA CORPORATION (韩国)",
        salespositionfover:"RM No. 608, City Airport Logis & Travel Building,87-22 Teheran-Ro,Kangnam-Gu,Seoul,Korea,06164",
        salestitlefive:"Mikasa Shoji Co., Ltd.(日本)",
        salespositionfive:"2-4-12 Shima-machi Osaka City Osaka Japan  540-0034",
		salesphonefive:'+81-6-6946-3737',
        salestitlesex:"SANSHIN ELECTRONICS CO., LTD. (日本)",
        salespositionsex:"4-4-12, SHIBA MINATO-KU​, TOKYO, 108-0014 Japan",
		salesphone:'+81-3-5484-7395',
        salestitlesens:"Lacewood International Corp.- ( 深圳、台湾)",
        salespositionsens:"(China)Room 401-3 East, CEC Information Building, No.1 Xin Wen Road, ShenZhen, P.R.C.",
        Employment:"招聘信息",
        Employmenttext:"BayHub Technology Ltd开发和销售Hub Processor产品，为移动设备和嵌入式应用提供高速SERDES(序列化器/反序列化器)连接技术",
        JoinUs:"加入我们",
        JoinUstext:"地址:武汉市东湖高新技术开发区华城大道9号武汉软件新城A61楼",
        JoinUstextOne:"邮箱:cindy.yang@bayhubtech.com lily.yang@bayhubtech.com",
        JoinUstextTwo:"联系方式:027-87538577 135451295687 13788040147",
        Jobdescription:"工作说明",
        ContactUsToPosition:"武汉市华城大道9号武汉软件新城A61楼 武汉东湖高新技术开发区",
        BayhubName:"倍昊电子科技有限公司",
		
		suzhou:'苏州：苏州高新区竹园路209号2号楼1309',
		hongkong:'香港：骆克中心19C 301-307室',
		wuhan:'武汉：武汉市东湖高新技术开发区花城大道9号武汉软件新城A6栋1楼101-2室',
		taiwan:'台湾：台北市104号南京东路二段160号8楼',
		readmore:'阅读更多',
        Previous:"上一条",
        Next:"下一条",
		banq:'版权所有©2013 BayHub保留所有权利。',
        youxiangs:"邮箱",
        webname:"公司网站",
        jianlis:"简历发送至",
    }

}

const ja = {
    message: {
        Home: 'ホーム',
        News: "ニュース",
        Product: "製品",
        Cooperation: "パートナーシップ",
        Contactus: "お問い合わせ",
        SuperPerformance: "高性能",
        SuperPerformanceText: "イメージ、ビデオ、組み込みアプリケーション向け、高い接続性と高い性能のHUBプロセッサテクノロジーを提供しています",
        UltraPowerSavings: "超低消費電力",
        UltraPowerSavingsText: "ノートブック、タブレット アプリケーション用、eMMC ,SD (Secure Digital) , Compact Flash cardsを接続する為のSERDES高速IOコネクテイビテイ・コントローラ製品において、大幅な省電力を実現する電力最適化技術を用いています。",
        HighQuality: "高品質",
        HighQualityText: "コンシューマ、産業用製品において、非常に低い不良率の高品質製品の提供を実現する設計およびテストを行っております。",
        GreaInnovation: "高い革新性",
        GreaInnovationText: "お客様のアプリケーションのソリューションを使い易く、そして費用効果の高いものにする創造的かつ革新的な製品を提供いたします",
        CompanyProfile: '会社概要',
        BayHubCompany: "BAYHUBとは",
        BayHubtextOne: "BayHub Technology Ltdは、モバイルデバイスおよび組み込みアプリケーション向けの高速SERDES（Serializer/Deserializer）接続テクノロジーを備えたハブプロセッサー製品を開発および販売しています。",
        BayHubtextTwo: "世界中にオフィスを構える同社には、設計、マーケティング、運用において成功した実績をもつ、リコー、NECなどで豊富な経験をしたチームが配置されております。",
        BayHubtextTher: "BayHubの製品は、コンピューター、コンシューマおよび産業用電子機器の世界有数のリーデイングカンパニーの皆様にお使いいただいております。",
        ProductPortfolio: "製品ポートフォリオ",
        EmccTitle: "eMMCストレージドライブコントローラ",
        EmccText: "eMMCストレージドライブは、SSDやHDDと比較して、小容量のソリッドステートストレージドライブ（16GB〜128GB）に最適です。これは、EducationノートPC、クラウドノートPC、Chromebook、およびIOTアプリケーションに適しています。",
        EmccTextOne: "eMMC 5.x HS200およびHS400仕様をサポートするために、SATAおよびPCIeを含む、さまざまなホストインターフェイスを備えた完全な製品ラインを提供します。",
        TextOne: "SATA-eMMC5.0 HS-200 ブリッジ",
        TextTwo: "eMMCからの起動サポート、",
        TextTher: "DEVSLPサポートを含む低消費電力を実現",
        ModernOne: "PCIe -eMMC5.0HS-200 ブリッジ ",
        ModernTwo: "eMMCからの起動サポート、",
        ModernTher: "Modern Standby サポートを含む低消費電力を実現",
        PcienOne: "PCIe -eMMC5.1 HS-400 ブリッジ ",
        PcienTwo: "eMMCからの起動サポート、",
        PcienTher: "Modern Standby サポートを含む低消費電力を実現",
        MediaSD: "SD カードリーダー",
        MediaSDtext: "BayHub製品ファミリは、SD2.x、SD3.x、およびSD4.xからSD7.xまでのSDカード仕様をサポートするPCIeホストインターフェイスコントローラーを提供します。　最先端のModern Standbyをサポートする低消費電力機能を備えております  当社の製品ファミリは、2000年以来、市場でテストおよび実証されており、SDカードの互換性が世界的に証明されています。 Intel様AMD様では、弊社製品を認定、プラットフォームコンポーネントリストに掲載していただいております。",
        SD_j_one: "PCIe -SD3 ブリッジ",
        SD_j_two: "Modern Standby サポートを含む低消費電力を実現",
        SD_j_ther: "",
        SD_f_one: "PCIe -SD4 ブリッジ",
        SD_f_two: "Modern Standby サポートを含む低消費電力を実現",
        SD_f_ther: "",
        SD_lv_one: "PCIe -SD3 ブリッジ",
        SD_lv_two: "Modern Standby サポートを含む低消費電力を実現",
        SD_lv_ther: "",
        SD_g_one: "PCIe -SD7 ブリッジ",
        SD_g_two: "Modern Standby サポートを含む低消費電力を実現",
        SD_g_ther: "",
        SD_bh_one: "SD３ネイテイブ インターフェース コンパニオンチップ",
        SD_bh_two: "SOCの既存 SD3 (SD-UHS-I) インターフェース において、長い信号配線でも使用可能にします",
        SD_bh_ther: "低消費電力を実現",
        Storage_title: "DSLR Media Card Hub プロセッサ",
        StorageTextOne: "最新のデジタル一眼レフカメラとミラーレスカメラでは、自然やスポーツなどの印象的な素晴らしいシーンを逃さず撮影する為に、超高解像度での高速連続撮影、高解像度のビデオ録画が必要になっています。",
        StorageTextTwo: "BayHub製品ファミリは、高機能カメラのそれらの期待に応える為、高速メディアカード、複数種のメディカカードに対して非常に高いパフォーマンスで読み出しと書き込みをサポート、およびデュアルスロットのサポートします。さらに、この製品ファミリは、組み込みOS環境において低消費電力を実現する為の最新のパワーマネージメント・テクノロジをサポートしています。",
        Storage_f_one: "PCIe -SD4 Hub ブリッジ ",
        Storage_f_two: "低消費電力",
        Storage_sd_one: "PCIe-SD4 Hub ブリッジ",
        Storage_sd_two: "デュアルSDホストインターフェース",
        Storage_sd_ther: "低消費電力",
        cardHerder: "レガシーコントローラ",
        card_text: "弊社のレガシー製品ラインファミリーは15年以上長期にわたり製造、販売されております。特にカードバスコントローラは世界で も数少の製品であり、スマートカードコントローラとともに高い実績と出荷量を誇っております。",
        card_tj_one: "PCIe -CardBusブリッジ",
        card_tj_two: "PC Card Standard Release 8.1 準拠",
        card_tj_ther: "CompactFlash Specification 4.1, UDMA Mode 4, 5, 6, and PIO Mode6 をサポート",
        card_tj_forv: "低消費電力",
        card_num_one: "CardBus パワースイッチ",
        card_num_two: "OZ600TJ0とのチップセット",
		card_num_two1:'',
        card_cr_one: "シングルチップUSB-CCID準拠",
        card_cr_two: "ISO 7816-3非同期 and 7816-10 をサポート",
        card_cr_ther: "smart cards 同期",
        card_cr_forv: "USB smart cards (eGate) との接続を提供",
        card_cr_five: "T=0 and T=1 smart card transmissionをサポート",
        NewsHeader: "ニュースセンター",
        NewsCenter: "ニュースセンター",
        ProductPortfController: "eMMCストレージドライブコントローラ",
        ProductPortDevice: "SD カードリーダー",
        ProductPortReaderr: "DSLR Media Card Hub プロセッサ",
        ProductPortfContLegacy: "レガシーコントローラ",
        Product_PortfController_text_one: "eMMCストレージドライブは、SSDやHDDと比較して、小容量のソリッドステートストレージドライブ（16GB〜128GB）に最適です。これは、Education ノートブックPC、クラウトノートブックPC、Chromebook、およびIOTアプリケーションに適しています。",
        Product_PortfController_text_two: "eMMC 5.x HS200およびHS400仕様をサポートするために、SATAおよびPCIeを含む、さまざまなホストインターフェイスを備えた完全な製品ラインを提供します。",
        Product_wr_one: "SATA-eMMC5.0 HS-200 ブリッジ",
        Product_wr_two: "eMMCからの起動サポート、",
        Product_wr_ther: "DEVSLPサポートを含む低消費電力を実現",
        Product_fj_one: "PCIe -eMMC5.0HS-200 ブリッジ ",
        Product_fj_two: "eMMCからの起動サポート、",
        Product_fj_ther: "DEVSLPサポートを含む低消費電力を実現",
        Product_bb_one: "PCIe -eMMC5.1 HS-400 ブリッジ",
        Product_bb_two: "eMMCからの起動サポート、",
        Product_bb_ther: "Modern Standby サポートを含む低消費電力を実現",
        ProductPortDevice_text: "BayHub製品ファミリは、SD2.x、SD3.x、およびSD4.xから最新のSD7. xまでのSDカード仕様をサポートするPCIeホストインターフェイスコントローラーを提供します。　最先端のModern Standbyをサポートする低電力機能を備えております当社の製品ファミリは、2000年以来、市場でテストおよび実証されており、SDカードの互換性が世界的に証明されています。 Intel様AMD様では、弊社製品を認定、プラットフォームコンポーネントリストに掲載していただいております。",
        Product_dfj_one: "PCIe -SD3 ブリッジ",
        Product_dfj_two: "Low power consumption",
        Product_dfj_ther: "Modern Standby サポートを含む低消費電力を実現",
        Product_bfj_one: "PCIe -SD4 ブリッジ",
        Product_bfj_two: "Low power consumption",
        Product_bfj_ther: "Modern Standby サポートを含む低消費電力を実現",
        Product_lv_one: "PCIe -SD3 ブリッジ",
        Product_lv_two: "Low power consumption",
        Product_lv_ther: "Modern Standby サポートを含む低消費電力を実現",
        Product_gg_one: "PCIe -SD7 ブリッジ",
        Product_gg_two: "Low power consumption",
        Product_gg_ther: "Modern Standby サポートを含む低消費電力を実現",
        Product_bh2_one: "SD３ネイテイブ インターフェース コンパニオン",
        Product_bh2_two: "SOCの既存 SD3 (SD-UHS-I) インターフェース において、長い信号配線でも使用可能にします",
        Product_bh2_ther: "低消費電力を実現",
        ProductCardHub: "DSLR Media Card Hub プロセッサ",
        ProductCardHubText: "最新のデジタル一眼レフカメラとミラーレスカメラでは、自然やスポーツなどの印象的な素晴らしいシーンを逃さず撮影する為に、超高解像度での高速連続撮影、高解像度のビデオ録画が必要になっています。",
        ProductCardHubTextOne: "BayHub製品ファミリは、高機能カメラのそれらの期待に応える為、高速メディアカード、複数種のメディカカードに対して非常に高いパフォーマンスで読み取りと書き込みをサポート、およびデュアルスロットのサポートします。さらに、この製品ファミリは、組み込みOS環境において低消費電力を実現する為の最新のパワーマネージメント・テクノロジをサポートしています。",
        CardHub_fj_one: "PCIe -SD4 Hub ブリッジ",
        CardHub_fj_two: "低消費電力",
        CardHub_sd_one: "PCIe-SD4 Hub ブリッジ",
        CardHub_sd_two: "デュアルSDホストインターフェース",
        CardHub_sd_ther: "低消費電力",
        ProductLegacy: "レガシーコントローラ",
        ProductLegacyText: "弊社のレガシー製品ラインファミリーは15年以上長期にわたり製造、販売されております。特にカードバスコントローラは世界で唯一の製品であり、スマートカードコントローラとともに高い実績と出荷量を誇っております。",
        ProductLegacy_tj_one: "PCIe -CardBusブリッジ",
        ProductLegacy_tj_two: "PC Card Standard Release 8.1 準拠",
        ProductLegacy_tj_ther: "CompactFlash Specification 4.1, UDMA Mode 4, 5, 6, and PIO Mode6 をサポート",
        ProductLegacy_tj_fove: "低消費電力",
        ProductLegacy_oz_one: "CardBusパワースィッチ",
        ProductLegacy_oz_two: "インダストリ標準のCardBusコントローラ技術との接続互換パワースイッチ",
        ProductLegacy_cr_one: "シングルチップUSB-CCID準拠",
        ProductLegacy_cr_two: "ISO 7816-3非同期 and 7816-10 をサポート",
        ProductLegacy_cr_ther: "smart cards 同期",
        ProductLegacy_cr_fove: "USB smart cards (eGate) との接続を提供",
        ProductLegacy_cr_fie: "T=0 and T=1 smart card transmissionをサポート",
        BusinessDistributors: "ビジネスディストリビューター",
        IndustryAssociation: "パートナーシップ",
        BayHubWorldwide: "世界におけるBAYHUB",
        BayHubWorldwidetext: "技術的お問い合わせや各種資料について、迅速に対応しておりますので、お気軽に下記フォームよりお問い合わせください。",
        Name: "お名前",
        Title: "お客様のご担当、お役職など",
        Company: "カンパニー",
        City: "ご住所",
        State: "都道府県",
        Country: "国",
        email: "E-mail",
        Briefdescription: "お問い合わせ内容",
        Code: "コード",
        Submit: "ご送信",
        messages:"情報を補完してください",
		Verification:'検証コードエラー',
        ContactUsto:"お問い合わせ",
        OfficeLocation:"事業、販売拠点",
        positionListtitle:"蘇州、中国",
        positionListpositiomtext:"#209, Zhuyuan Road, GaoXin District, Suzhou, China",
        positionListtitleOne:"武漢、中国",
        positionListpositiomOnetext:"Floor 1, Building A6, Wuhan Best City, No.9 Huacheng Avenue, The East Lake High-tech Development Zone, Wuhan",
        positionListtitleTwo:"香港、中国",
        positionListpositiomTwotext:"Kong ,China RM 19C Lockhart CTR 301-307 Lockhart RD , Wan Chai HK",
        positionListtitlether:"台湾、中国",
        positionListpositiomthertext:"8F., No.160, Sec. 2, Nanjing E. Rd., Taipei City 104, Taiwan",
        positionListtitlefove:"カリフォルニア、USA",
        positionListpositiomfovetext:"5201 Great America Pkwy., Suite 320, Santa Clara , CA 95054",
        positionListtitlefive:"東京、日本",
        positionListpositiomfivetext:"郵便番号：105-0001 東京都港区虎ノ門3-14-9-309",
        SalesDistributor:"ディストリビューター・お取扱店",
        salestitleone:"Promate Electronic Co., LTD. (Taiwan)",
        salesposition:"4F 32, SEC. 1 Huan Shan RD., Neihu , Taipei 114 Taiwan , R.O.C.",
        salestitletwo:"Promate (台北、台湾)",
        salespositiontwo: "4F 32, SEC. 1 Huan Shan RD., Neihu , Taipei 114 Taiwan , R.O.C.",
        salestitlether:"Promate China (深圳、中国)",
        salespositionther:"Room 1409-1416, 14/F, FuChun-DongFang Building, No. 7006 Shennan Road,    Shenzhen, China",
        salestitlefover:"KOREA MIKASA (ソウル、韓国)",
        salespositionfover:"RM No. 608, City Airport Logis & Travel Building,87-22 Teheran-Ro,Kangnam-Gu,Seoul,Korea,06164",
        salestitlefive:"ミカサ商事株式会社(日本)",
        salespositionfive:"郵便番号540-0034 大阪府大阪市中央区島町二丁目4番12号",
		salesphonefive:'06-6946-3737',
        salestitlesex:"三信電気株式会社 (東京、日本)",
        salespositionsex:"東京都港区芝四丁目４番12号",
		salesphone:'03-5484-7395',
        salestitlesens:"Lacewood International Corp.- ( 深圳、台湾)",
        salespositionsens:"(China)Room 401-3 East, CEC Information Building, No.1 Xin Wen Road, ShenZhen, P.R.C.",
        Employment:"採用情報",
        Employmenttext:"BayHub Technology はMobileデバイス、組み込み機器向けの高速のSERDESコネクテイビテイ技術を備えたHUBプロセッサー製品の開発およびマーケテイングを行っています",
        JoinUs:"私たちに加わって",
        JoinUstext:"住所:武漢市東湖ハイテク開発区華城大道9号武漢ソフトウェア新都市a61  ",
        JoinUstextOne:"階電子メール:cindy.yang@bayhubtech.com",
        JoinUstextTwo:"連絡先:027-87538577  135451295687 13788040147",
        Jobdescription:"仕事の説明",
        ContactUsToPosition:"武漢市華城大道9号武漢ソフトウェア新都市a61楼武漢東湖ハイテク開発区",
        BayhubName:"ベイハブテクノロジー",
		
		suzhou:'蘇州：第209、Zhuyuan道、ハイテク地帯',
		hongkong:'香港：部屋301 - 307、19 C、Lockhartセンター',
		wuhan:'ウーハン：客室101 - 2、1階、建物A 6、東湖ハイテク開発区、No . 9',
		taiwan:'台湾：160番、8階。2南京東道、台北、台湾104',
		readmore:'Read More',
        Previous:"前章",
        Next:"次の一条",
		banq:'Copyright @2013 BayHub all rights reserved.',
        youxiangs:"Eメール",
        webname:"会社ウェブサイト",
        jianlis:"履歴書を送付する",
    }

}

export default {
    en,
    zh,
    ja
}