<template>
  <div>
    <div class="body">
      <div class="lagins">
        <img src="../assets/images/logo.png" alt="" />
        <div class="header_left">
          <HeaderNav></HeaderNav>
          <div class="lagins_Language">
            <Language style="width: 100%; height: 100%"></Language>
          </div>
        </div>
      </div>
      <Banner
        @bannerEmit="bannerEmit"
        :indexs="indexs"
        :pagePostion="pagePostion"
        :mouseActive="mouseActive"
        @mouseActiveApi="mouseActiveApi"
        @pagePostionApi="pagePostionApi"
      ></Banner>
      <div class="banners">
        <div class="banners_tops">
          <div
            class="banners_one"
            :style="active === 0 ? 'display: block;' : 'display: none;'"
          >
            <div class="banners_onetitle">
              {{ $t("message.SuperPerformance") }}
			  <div class="banners_two_border"></div>
            </div>
            <p class="banners_one_p">
              {{ $t("message.SuperPerformanceText") }}
            </p>
          </div>
          <div
            class="banners_two"
            :style="active === 1 ? 'display: block;' : 'display: none;'"
          >
            <div class="banners_twotitle">
              {{ $t("message.UltraPowerSavings") }}
              <div class="banners_two_border"></div>
            </div>
            <p class="banners_two_p">
              {{ $t("message.UltraPowerSavingsText") }}
            </p>
          </div>
          <div
            class="banners_two banners_Ther"
            :style="active === 2 ? 'display: block;' : 'display: none;'"
          >
            <div class="banners_twotitle">
              {{ $t("message.HighQuality") }}
              <div class="banners_two_border"></div>
            </div>
            <p class="banners_two_p">
              {{ $t("message.HighQualityText") }}
            </p>
          </div>
          <div
            class="banners_two banners_fover"
            :style="active === 3 ? 'display: block;' : 'display: none;'"
          >
            <div class="banners_twotitle">
              {{ $t("message.GreaInnovation") }}
              <div class="banners_two_border"></div>
            </div>
            <p class="banners_two_p">
              {{ $t("message.GreaInnovationText") }}
            </p>
          </div>
          <div
            class="pagesto"
            @mouseenter="mouseoverActive()"
            @mouseleave="mouseleaveActive()"
          >
            <span :class="active === 0 ? 'activeColor' : ''" @click="topages(0)"
              >01</span
            >
            <span :class="active === 1 ? 'activeColor' : ''" @click="topages(1)"
              >02</span
            >
            <span :class="active === 2 ? 'activeColor' : ''" @click="topages(2)"
              >03</span
            >
            <span :class="active === 3 ? 'activeColor' : ''" @click="topages(3)"
              >04</span
            >
            <img
              src="../assets/images/zuo.png"
              alt=""
              @click="topagesPosition(1)"
            />
            <img
              src="../assets/images/you.png"
              alt=""
              @click="topagesPosition(2)"
            />
          </div>
        </div>
      </div>
    </div>
    <nav class="home_center">
      <div class="profile">
        <div class="profile_position">
          <div class="title">
            <span>{{ $t("message.CompanyProfile") }}</span>
            <div class="title_borders"></div>
          </div>
          
          <div class="profile_text">
            <div class="ulposis"></div>
            <div
              :class="
                isHow
                  ? 'donghuajianchu profile_text_header'
                  : 'profile_text_header'
              "
            >
              <div class="yinghaoOne">
                <img src="../assets/images/zhengyinghao.png" alt="" />
              </div>
              <!-- <span>{{ $t("message.BayHubCompany") }}</span> -->
			  <div class="profile_logo">
			    <img
			      style="width: 100%;"
			      src="../assets/images/blogo.png"
			      alt=""
			    />
			  </div>
              <p class="BayHubtext">
                {{ $t("message.BayHubtextOne") }}
              </p>
              <p class="BayHubtext">
                {{ $t("message.BayHubtextTwo") }}
              </p>
              <p class="BayHubtext">
                {{ $t("message.BayHubtextTher") }}
              </p>
              <div class="yinghaoOne" style="float: right">
                <img src="../assets/images/fanying.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <img class="profile_bg" src="../assets/images/bejing.jpg" alt="" />
      </div>
      <div class="ProductPortfolio">
        <span>{{ $t("message.ProductPortfolio") }}</span>
        <div class="Product_border"></div>
      </div>
    </nav>
    <div class="index_list">
      <div class="index_list_imgone">
        <img
          src="../assets/images/B1G.png"
          style="width: 6.82rem; height: 11.75rem"
          alt=""
        />
      </div>
      <div class="index_list_imgtow">
        <img
          src="../assets/images/B2G.png"
          style="width: 11rem; height: 12.1rem"
          alt=""
        />
      </div>
      <div class="index_list_imgther">
        <img
          src="../assets/images/BG3.png"
          style="width: 6.661rem; height: 8.88rem"
          alt=""
        />
      </div>
      <div class="index_list_center">
        <div class="lsit_left_one">
          <div class="lsit_left">
            <img
              class="lsit_left_img"
              src="../assets/images/VectorSmartObject.png"
              alt=""
            />
            <img class="lsits_img" src="../assets/images/pic1.png" alt="" />
          </div>
          <div :class="isfeiru ? 'feiruxiaoguo lsit_right' : 'lsit_right'">
            <div class="lsit_right_top">
              <span>{{ $t("message.EmccTitle") }}</span>
              <div class="lsit_right_top_border"></div>
            </div>
            <p class="lsit_right_center">
              {{ $t("message.EmccText") }}<br />
              {{ $t("message.EmccTextOne") }}
            </p>
            <div class="lsit_right_ul">
              <div class="lsit_right_li">
                <span class="lsit_right_li_dian"></span> OZ788WR2
              </div>
              <div class="lsit_right_li_text">
                {{ $t("message.TextOne") }}<br />
                {{ $t("message.TextTwo") }}<br />
                {{ $t("message.TextTher") }}<br />
              </div>
              <div class="lsit_right_li">
                <span class="lsit_right_li_dian"></span> BH720FJ1
              </div>
              <div class="lsit_right_li_text">
                {{ $t("message.ModernOne") }}<br />
                {{ $t("message.ModernTwo") }}<br />
                {{ $t("message.ModernTher") }}<br />
              </div>
              <div class="lsit_right_li">
                <span class="lsit_right_li_dian"></span> BH799BB
              </div>
              <div class="lsit_right_li_text">
                {{ $t("message.PcienOne") }}<br />
                {{ $t("message.PcienTwo") }}<br />
                {{ $t("message.PcienTher") }}<br />
              </div>
            </div>
          </div>
        </div>
        <div class="lsit_left_two">
          <div
            :class="
              isfeiruTher
                ? 'feiruxiaoguoTwo lsit_left_two_left'
                : 'lsit_left_two_left'
            "
          >
            <div class="lsit_two_left_top">
              <span>{{ $t("message.MediaSD") }}</span>
              <div class="lsit_two_left_top_border"></div>
            </div>
            <p class="lsit_two_left_center">
              {{ $t("message.MediaSDtext") }}
            </p>
            <div class="lsit_two_left_ul">
              <div class="lsit_two_left_all_li">
                <div class="lsit_two_left_ul_li">
                  <span class="lsit_two_left_ul_li_dian"></span> BH622FJ1
                </div>
                <div class="lsit_two_left_ul_li_text">
                  {{ $t("message.SD_j_one") }}<br />
                  {{ $t("message.SD_j_two") }}<br />
                  {{ $t("message.SD_j_ther") }}
                </div>
              </div>
              <div class="lsit_two_left_all_li">
                <div class="lsit_two_left_ul_li">
                  <span class="lsit_two_left_ul_li_dian"></span> BH760FJ2
                </div>
                <div class="lsit_two_left_ul_li_text">
                  {{ $t("message.SD_f_one") }}<br />
                  {{ $t("message.SD_f_two") }}<br />
                  {{ $t("message.SD_f_ther") }}
                </div>
              </div>
              <div class="lsit_two_left_all_li">
                <div class="lsit_two_left_ul_li">
                  <span class="lsit_two_left_ul_li_dian"></span> OZ711LV2
                </div>
                <div class="lsit_two_left_ul_li_text">
                  {{ $t("message.SD_lv_one") }}<br />
                  {{ $t("message.SD_lv_two") }}<br />
                  {{ $t("message.SD_lv_ther") }}
                </div>
              </div>
              <div class="lsit_two_left_all_li">
                <div class="lsit_two_left_ul_li">
                  <span class="lsit_two_left_ul_li_dian"></span> BH770GG7
                </div>
                <div class="lsit_two_left_ul_li_text">
                  {{ $t("message.SD_g_one") }}<br />
                  {{ $t("message.SD_g_two") }}<br />
                  {{ $t("message.SD_g_ther") }}
                </div>
              </div>
              <div class="lsit_two_left_all_li">
                <div class="lsit_two_left_ul_li">
                  <span class="lsit_two_left_ul_li_dian"></span> BH201
                </div>
                <div class="lsit_two_left_ul_li_text">
                  {{ $t("message.SD_bh_one") }}<br />
                  {{ $t("message.SD_bh_two") }}<br />
                  {{ $t("message.SD_bh_ther") }}
                </div>
              </div>
            </div>
          </div>
          <div class="lsit_left_two_right">
            <div class="VectorSmartObject2">
              <img src="../assets/images/VectorSmartObject2.png" alt="" />
            </div>
            <img
              class="lsit_left_two_right_img"
              src="../assets/images/pic2.png"
              alt=""
            />
          </div>
        </div>
        <!-- *************************** -->
        <div class="lsit_left_one">
          <div class="lsit_left">
            <img
              class="lsit_left_img"
              src="../assets/images/VectorSmartObject3.png"
              alt=""
            />
            <img class="lsits_img" src="../assets/images/pic3.png" alt="" />
          </div>
          <div :class="isfeiruTwo ? 'feiruxiaoguo lsit_right' : 'lsit_right'">
            <div class="lsit_right_top">
              <span>{{ $t("message.Storage_title") }}</span>
              <div class="lsit_right_top_border colorsOne"></div>
            </div>
            <p class="lsit_right_center">
              {{ $t("message.StorageTextOne") }}<br /><br />
              {{ $t("message.StorageTextTwo") }}
            </p>
            <div class="lsit_right_ul">
              <div class="lsit_right_li colors">
                <span class="lsit_right_li_dian colorsOne"></span> OZ787FJ2
              </div>
              <div class="lsit_right_li_text">
                {{ $t("message.Storage_f_one") }}<br />
                {{ $t("message.Storage_f_two") }}
              </div>
              <div class="lsit_right_li colors">
                <span class="lsit_right_li_dian colorsOne"></span> OZ797SD2
              </div>
              <div class="lsit_right_li_text">
                {{ $t("message.Storage_sd_one") }}<br />
                {{ $t("message.Storage_sd_two") }}<br />
                {{ $t("message.Storage_sd_ther") }}
              </div>
            </div>
          </div>
        </div>
        <div class="lsit_left_two">
          <div
            :class="
              isfeiruFove
                ? 'feiruxiaoguoTwo lsit_left_two_left'
                : 'lsit_left_two_left'
            "
          >
            <div class="lsit_two_left_top">
              <span>{{ $t("message.cardHerder") }}</span>
              <div class="lsit_two_left_top_border colorstwo"></div>
            </div>
            <p class="lsit_two_left_center">
              {{ $t("message.card_text") }}
            </p>
            <div class="lsit_two_left_ul">
              <div class="lsit_two_left_all_li" style="width: 100%">
                <div class="lsit_two_left_ul_li color2">
                  <span class="lsit_two_left_ul_li_dian colorstwo"></span>
                  OZ600TJ0
                </div>
                <div class="lsit_two_left_ul_li_text">
                  {{ $t("message.card_tj_one") }}<br />
                  {{ $t("message.card_tj_two") }}<br />
                  {{ $t("message.card_tj_ther") }}<br />
                  {{ $t("message.card_tj_forv") }}
                </div>
              </div>
              <div class="lsit_two_left_all_li" style="width: 100%">
                <div class="lsit_two_left_ul_li color2">
                  <span class="lsit_two_left_ul_li_dian colorstwo"></span>
                  OZ2210
                </div>
                <div class="lsit_two_left_ul_li_text">
                  {{ $t("message.card_num_one") }}<br />
                  {{ $t("message.card_num_two") }}<br />
				  {{ $t("message.card_num_two1") }}
                </div>
              </div>
              <div class="lsit_two_left_all_li" style="width: 100%">
                <div class="lsit_two_left_ul_li color2">
                  <span class="lsit_two_left_ul_li_dian colorstwo"></span>
                  OZ77CR6
                </div>
                <div class="lsit_two_left_ul_li_text">
                  {{ $t("message.card_cr_one") }}<br />
                  {{ $t("message.card_cr_two") }}<br />
                  {{ $t("message.card_cr_ther") }}<br />
                  {{ $t("message.card_cr_forv") }}<br />
                  {{ $t("message.card_cr_five") }}
                </div>
              </div>
            </div>
          </div>
          <div class="lsit_left_two_right">
            <div class="VectorSmartObject2">
              <img src="../assets/images/VectorSmartObject4.png" alt="" />
            </div>
            <img
              class="lsit_left_two_right_img"
              src="../assets/images/pic4.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="NewsCenter">
      {{ $t("message.NewsHeader") }}
      <div class="News_border"></div>
    </div>
    <div class="lsitbanner">
      <Lsitbanner></Lsitbanner>
    </div>
    <div class="moertitle">
      <span>{{ $t("message.IndustryAssociation") }}</span>
      <div class="moertitle_borders"></div>
    </div>
    <!-- <div class="slitbad" style="margin-top: 0.65rem">
      <el-carousel height="1.5rem">
        <el-carousel-item v-for="item in 1" :key="item" class="lsitall">
          <div class="lsit_img" v-for="(itm, index) in colorList" :key="index">
            <img style="width: 100%; height: 100%" :src="itm.img" alt="" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div> -->
	<div class="fot_swiper">
		<swiper :options="swiperOptions">
		  <swiper-slide v-for='(item,index) in colorList' :key='index'>
			<div class="sild">
			  <img :src="item.img" />
			</div>
		  </swiper-slide>
		</swiper>
		<div class="swiper-button-prev" slot="button-prev"></div>
		<div class="swiper-button-next" slot="button-next"></div>
	</div>
    <div class="index_bottom" @click="tolsitad">{{$t('message.readmore')}}</div>
    <!-- <div class="topsa" v-if="ishands" @click="lsitads">
      <img src="../assets/images/zhiidng.png" alt="" />
    </div> -->
	<topsa></topsa>
    <Tooters></Tooters>
  </div>
</template>

<script>
import Banner from "../components/banner/index";
import Language from "../components/language";
import Lsitbanner from "../components/lsitbanner";
import HeaderNav from "../components/headernav";
import Tooters from "../components/footers";
import topsa from '../components/topsa.vue';
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import 'swiper/css/swiper.css'
export default {
  name: "index",
  components: {
    Language,
    Banner,
    Lsitbanner,
    HeaderNav,
    Tooters,
	Swiper,
	SwiperSlide,
	topsa
  },
  data() {
    return {
      active: 0,
      indexs: 0,
      pagePostion: 0,
      mouseActive: 0,
      isHow: false,
      isfeiru: false,
      isfeiruTwo: false,
      isfeiruTher: false,
      isfeiruFove: false,
      ishands: false,
      colorList: [
        {
          color: "red",
          img: require("../assets/images/intel.png"),
        },
        {
          color: "#cccccc",
          img: require("../assets/images/AMD.png"),
        },
        {
          color: "#000000",
          img: require("../assets/images/google.png"),
        },
        {
          color: "#008884",
          img: require("../assets/images/mic.png"),
        },
		{img:require('../assets/images/pci1.png')},
		{img:require('../assets/images/pcm1.png')},
		{img:require('../assets/images/fips.png')},
		{img:require('../assets/images/emv1.png')},
		{img:require('../assets/images/usb1.png')}
      ],
	  swiperOptions:{
	    slidesPerView: 4,
	    spaceBetween:10,
		observer:true,
		observeParents: true,
		setWrapperSize :true,
	    autoplay:{
	      delay:4000,
	      disableOnInteraction:false
	    },
	    navigation: {
	      nextEl: '.swiper-button-next',
	      prevEl: '.swiper-button-prev',
	    },
	  },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    lsitads() {
      window.scrollTo(0, 0);
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 200) {
        this.isHow = true;
      } else {
        this.isHow = false;
      }
      if (scrollTop > 950) {
        this.isfeiru = true;
      } else {
        this.isfeiru = false;
      }
      if (scrollTop > 1920) {
        this.isfeiruTher = true;
      } else {
        this.isfeiruTher = false;
      }
      if (scrollTop > 2850) {
        this.isfeiruTwo = true;
      } else {
        this.isfeiruTwo = false;
      }
      if (scrollTop > 3750) {
        this.isfeiruFove = true;
      } else {
        this.isfeiruFove = false;
      }
      if (scrollTop > 1860) {
        this.ishands = true;
      } else {
        this.ishands = false;
      }
    },
    bannerEmit(e) {
      this.active = e;
    },
    pagePostionApi(e) {
      this.pagePostion = e;
    },
    mouseActiveApi(e) {
      this.mouseActive = e;
    },
    topages(index) {
      this.indexs = index;
      this.active = index;
    },
    topagesPosition(e) {
      this.pagePostion = e;
    },
    mouseoverActive() {
      this.mouseActive = 1;
    },
    mouseleaveActive() {
      this.mouseActive = 2;
    },
    tolsitad() {
      this.$router.push({ path: "/cooperation" });
    },
  },
};
</script>

<style lang="scss" scoped>
.lagins {
  width: 14.55rem;
  margin-top: 0.5rem;
  position: absolute;
  right: 2.4rem;
  top: 0;
  z-index: 9999 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header_left {
    display: flex;
    align-items: center;
    .lagins_Language {
		cursor: pointer;
      width: 0.6rem;
      height: 0.6rem;
    }
  }
}
.body {
  width: 100%;
  overflow: hidden;
  position: relative;
  .banners {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 14.55rem;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 8888;
    .banners_tops {
      // position: relative;
      .banners_one {
        display: block;
        animation: dongHua 3s 1 ease-in-out;
        .banners_onetitle {
          font-size: 0.64rem;
          // font-weight: 600;
          color: #42e6fc;
          line-height: 0.68rem;
		  .banners_two_border {
		    width: 0.57rem;
		    height: 0.07rem;
		    margin-top: 0.14rem;
		    background: #1dcae5;
		  }
        }
        .banners_one_p {
          margin-top: 0.73rem;
          width: 6.7rem;
          font-size: 0.24rem;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.36rem;
          opacity: 0.9;
        }
      }
      .banners_two {
        display: none;
        animation: dongHua 3s 1 ease-in-out;
        .banners_twotitle {
          font-size: 0.64rem;
          // font-weight: 600;
          color: #ffffff;
          line-height: 0.68rem;
          text-shadow: 0px 0.06rem 0.35rem rgba(16, 56, 123, 0.23);
          .banners_two_border {
            width: 0.57rem;
            height: 0.07rem;
            margin-top: 0.14rem;
            background: #1dcae5;
          }
        }
        .banners_two_p {
          width: 7.1rem;
          margin-top: 0.2rem;
          font-size: 0.24rem;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.36rem;
          text-shadow: 0px 0.06rem 0.35rem rgba(16, 56, 123, 0.12);
        }
      }
      @keyframes dongHua {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.1;
        }
        100% {
          opacity: 1;
        }
      }
      .banners_Ther {
        display: none;
      }
      .banners_fover {
        display: none;
      }
      .pagesto {
        display: flex;
        align-items: center;
        font-size: 0.18rem;
        font-weight: 600;
        color: #ffffff;
        line-height: 0.32rem;
        z-index: 9999;
        // margin-top: 100px;
        position: absolute;
        bottom: 0.3rem;
        span {
          margin-right: 0.15rem;
          cursor: pointer;
          opacity: 0.6;
        }
        img {
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 0.17rem;
          cursor: pointer;
          opacity: 0.6;
        }
        .activeColor {
          color: #ffffff;
          opacity: 1 !important;
        }
      }
    }
  }
}
.home_center {
  width: 14.55rem;
  margin: 0 auto;
  overflow: hidden;
}
.profile {
  margin-top: 0.4rem;
  position: relative;
  .profile_bg {
    width: 100%;
  }
  .profile_position {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    .title {
      margin-top: 0.2rem;
      text-align: center;
      font-size: 0.36rem;
      font-weight: 600;
      color: #072754;
      position: relative;
      .title_borders {
        width: 0.57rem;
        height: 0.07rem;
        margin-top: 0.26rem;
        background: #6a79f7;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -0.26rem;
        margin: auto;
      }
    }
    .profile_logo {
      width: 2.8rem;
      margin: 0 auto;
    }
    .profile_text {
      width: 7.73rem;
      height: 3.6rem;
      background: #ffffff;
      margin: 0 auto;
      margin-top: 1rem;
      border-radius: 0.1rem;
      box-shadow: 0.03rem 0.05rem 0.07rem #e6e4e4;
      padding: 0.35rem;
      position: relative;
      .ulposis {
        position: absolute;
        top: -0.2rem;
        left: 0px;
        right: 0px;
        margin: auto;
        width: 0;
        height: 0;
        border-left: 0.25rem solid transparent;
        border-right: 0.25rem solid transparent;
        border-bottom: 0.25rem solid #ffffff;
      }
      .profile_text_header {
        text-align: center;
        font-size: 0.3rem;
        font-weight: 500;
        color: #072754;

        .yinghaoOne {
          width: 0.6rem;
          height: 0.48rem;
          float: left;
          img {
            width: 100%;
            height: 100%;
          }
        }
        span {
          display: inline-block;
          margin-top: 0.25rem;
        }
        .BayHubtext {
          margin-top: 0.36rem;
          font-size: 0.16rem;
          font-weight: 400;
          color: #072754;
          opacity: 0.8;
          text-align: left;
          line-height: 0.24rem;
        }
      }
    }
  }
}
/* .donghuajianchu {
  animation: dongHua 3s 1 ease-in-out;
  @keyframes dongHua {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
} */
.ProductPortfolio {
  margin-top: 0.2rem;
  font-size: 0.36rem;
  font-weight: 600;
  color: #072754;
  .Product_border {
    margin-top: 0.26rem;
    width: 0.57rem;
    height: 0.07rem;
    background: #6a79f7;
  }
}
.index_list {
  width: 100%;
  margin-top: .3rem;
  // padding-bottom: 800px !important;
  //   overflow: hidden;
  position: relative;
  .index_list_imgtow {
    width: 100%;
    text-align: right;
    margin-top: 0.1rem;
    // margin-bottom: 157px;
  }
  .index_list_center {
    width: 14.55rem;
    position: absolute;
    left: 0;
    right: 0;
    /* bottom: 0; */
    top: 0;
    margin: auto;
    padding: 0.54rem 0;
    .lsit_left_one {
      display: flex;
      //   align-items: center;
      margin-bottom: 1.57rem;
      .lsit_left {
        width: 5.9rem;
        height: 6.7rem;
        position: relative;
        .lsit_left_img {
          width: 2.59rem;
          height: 1.94rem;
        }
        .lsits_img {
          width: 5.55rem;
          height: 6.44rem;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }
    .lsit_right {
      width: 7.18rem;
      margin-top: 0.53rem;
      margin-left: 1.18rem;
      .lsit_right_top {
        font-size: 0.3rem;
        font-weight: 400;
        color: #072754;
        .lsit_right_top_border {
          width: 1.74rem;
          height: 0.02rem;
          background: #6666ff;
          margin-top: 0.26rem;
        }
      }
      .lsit_right_center {
        width: 7.18rem;
        font-size: 0.16rem;
        font-weight: 400;
        color: #072754;
        line-height: 0.24rem;
        opacity: 0.8;
        margin-top: 0.28rem;
      }
      .lsit_right_ul {
        .lsit_right_li {
          font-size: 0.18rem;
          font-weight: bold;
          color: #4450ff;
          line-height: 0.24rem;
          opacity: 0.8;
          margin-top: 0.26rem;
		  display: flex;
		  flex-flow: row;
		  align-items: center;
          .lsit_right_li_dian {
            display: inline-block;
            width: 0.07rem;
            height: 0.07rem;
            background: #3441ff;
			margin-right: 0.1rem;
            border-radius: 50%;
          }
        }
        .lsit_right_li_text {
          width: 3.4rem;
          font-size: 0.16rem;
          font-weight: 400;
          color: #072754;
          line-height: 0.24rem;
		  margin-top: .05rem;
          opacity: 0.8;
        }
      }
    }
    .lsit_left_two {
      display: flex;
      margin-bottom: 1.57rem;
      .lsit_left_two_left {
        width: 7.27rem;
        margin-top: 0.5rem;
        .lsit_two_left_top {
          font-size: 0.3rem;
          font-weight: 400;
          color: #072754;
          .lsit_two_left_top_border {
            width: 1.74rem;
            height: 0.02rem;
            background: #f7850f;
            margin-top: 0.26rem;
          }
        }
        .lsit_two_left_center {
          width: 7.27rem;
          font-size: 0.16rem;
          font-weight: 400;
          color: #072754;
          line-height: 0.24rem;
          opacity: 0.8;
          margin-top: 0.26rem;
        }
        .lsit_two_left_ul {
          margin-top: 0.6rem;
          display: flex;
          flex-flow: wrap;
          .lsit_two_left_all_li {
            width: 3.4rem;
            .lsit_two_left_ul_li {
              font-size: 0.18rem;
              font-weight: bold;
              color: #f7850f;
              line-height: 0.24rem;
              opacity: 0.8;
				display: flex;
				flex-flow: row;
				align-items: center;
              .lsit_two_left_ul_li_dian {
                width: 0.07rem;
                height: 0.07rem;
				display: inline-block;
                background: #f7850f;
                border-radius: 50%;
				margin-right: 0.1rem;
              }
            }
            .lsit_two_left_ul_li_text {
              width: 90%;
              font-size: 0.16rem;
              font-weight: 400;
              color: #072754;
              line-height: 0.24rem;
              opacity: 0.8;
			  margin-top: .05rem;
              margin-bottom: 0.27rem;
            }
          }
        }
      }
      .lsit_left_two_right {
        width: 5.94rem;
        height: 6.74rem;
        margin-left: 1.2rem;
        position: relative;
        .VectorSmartObject2 {
          width: 2.59rem;
          height: 1.94rem;
          float: right;
          img {
            width: 100%;
            height: 100%;
            opacity: 0.8;
          }
        }
        .lsit_left_two_right_img {
          width: 5.55rem;
          height: 6.44rem;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
  }
}
.NewsCenter {
  width: 100%;
  text-align: center;
  font-size: 0.36rem;
  font-weight: 600;
  color: #072754;
  line-height: 0.6rem;
  position: relative;
  .News_border {
    width: 0.57rem;
    height: 0.07rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.1rem;
    margin: auto;
    background: #6a79f7;
  }
}
.feiruxiaoguo {
  animation: fadeInDown 1s;
  -webkit-animation: fadeInDown 1s;
  @keyframes fadeInDown {
    0% {
      margin-left: 4rem;
      opacity: 0;
    }
    100% {
      margin-left: 1.18rem;
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeInDown {
    0% {
      margin-left: 4rem;
      opacity: 0;
    }
    100% {
      margin-left: 1.18rem;
      opacity: 1;
    }
  }
}
.feiruxiaoguoTwo {
  animation: fadeInDown 1s;
  -webkit-animation: fadeInDown 1s;
  @keyframes fadeInDown {
    0% {
      margin-right: 4rem;
      opacity: 0;
    }
    100% {
      margin-right: 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeInDown {
    0% {
      margin-right: 4rem;
      opacity: 0;
    }
    100% {
      margin-right: 0;
      opacity: 1;
    }
  }
}
.colors {
  color: #58de98 !important;
}
.colorsOne {
  background: #58de98 !important;
}
.color2 {
  color: #23bdde !important;
}
.colorstwo {
  background: #23bdde !important;
}
.lsitbanner {
  width: 14.55rem;
  height: 5.4rem;
  margin: 0 auto;
  margin-top: 0.6rem;
}
.moertitle {
  margin-top: 0.87rem;
  font-size: 0.36rem;
  font-weight: bold;
  color: #020202;
  line-height: 0.6rem;
  margin-left: 2.37rem;
  .moertitle_borders {
    width: 0.57rem;
    height: 0.07rem;
    background: #6a79f7;
    margin-top: 0.19rem;
  }
}
.slitbad {
  width: 14.55rem;
  margin: 0 auto;
}
.lsitall {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.lsit_img {
  width: 3.26rem;
  height: 1.26rem;
  overflow: hidden;
  box-shadow: 0px 0.08rem 0.08rem 0.02rem #f6f6f6;
}
.lsit_img img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.6s;
}
.lsit_img img:hover {
  transform: scale(1.1);
}
.index_bottom {
  width: 2.2rem;
  height: 0.58rem;
  margin: 0 auto;
  margin-top: 0.88rem;
  margin-bottom: 1rem;
  background: #00d577;
  border-radius: 0.03rem;
  text-align: center;
  font-size: 0.18rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.58rem;
  cursor: pointer;
}
.topsa {
  width: 1rem;
  height: 1rem;
  position: fixed;
  bottom: 3.5rem;
  right: .5rem;
  z-index: 9999;
  img {
    width: 100%;
    height: 100%;
  }
}

.fot_swiper{
      margin-top: 0.59rem;
      padding: 0 2.37rem;
      box-sizing: border-box;
      position: relative;
      /deep/ .swiper-button-prev,.swiper-button-next{
        color: #00D577;
		font-weight: bold;
		outline: none;
      }
	  /deep/ .swiper-button-prev{
		  left: 1.8rem;
	  }
	  /deep/ .swiper-button-next{
		  right: 1.8rem;
	  }
      /deep/ .swiper-wrapper{
        display: flex;
        flex-flow: row;
        align-items: center;
        height: 1.26rem;
      }
      /deep/ .swiper-slide{
        display: flex;
        flex-flow: row;
		justify-content: center;
        align-items: center;
      }
      .sild{
        width: 3.26rem;
        height: 1.10rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0.08rem 0.08rem 0.02rem rgba(246, 246, 246, 0.9);
        img{
          height: 1.10rem;
          display: block;
        }
      }
    }
</style>