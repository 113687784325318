<template>
  <div>
    <div class="news_bg">
      <div class="lagins">
        <div>
          <img src="../assets/images/logo.png" alt="" />
        </div>
        <div class="header_left">
          <HeaderNav></HeaderNav>
          <div class="lagins_Language">
            <Language style="width: 100%; height: 100%"></Language>
          </div>
        </div>
      </div>
      <div class="center_text">{{ $t("message.ContactUsto") }}</div>
      <img src="../assets/images/piclianxi.jpg" alt="" />
    </div>
    <div class="contact_us">
      <div class="contact_center">
        <div class="contact__title">
          {{ $t("message.OfficeLocation") }}
          <div class="contact__title_headers"></div>
        </div>
        <div class="contact__top_list">
          <div
            class="contact__top_list_lis"
            v-for="(item, index) in positionList"
            :key="index"
          >
            <img class="imgs1" :src="item.imgOne" alt="" />
            <img class="imgs2" :src="item.imgTwo" alt="" />
            <div class="position_name">{{ $t(item.title) }}</div>
            <div class="position_mass">
              <div class="position_mass_list">
                <img
                  class="imgsOne"
                  style="width: 0.15rem; height: 0.2rem"
                  :src="item.posiImgOne"
                  alt=""
                />
                <img
                  class="imgsTwo"
                  style="width: 0.15rem; height: 0.2rem"
                  :src="item.posiImgTwo"
                  alt=""
                />
                <p>{{ $t(item.positiomtext) }}</p>
              </div>
              <div class="position_mass_list" v-if="item.phone">
                <img class="imgsOne" :src="item.callImgOne" alt="" />
                <img class="imgsTwo" :src="item.callImgTwo" alt="" />
                <p>{{ item.phone }}</p>
              </div>
            </div>
          </div>
          <div style="width: 3.5rem;height: 3.35rem;padding: 0.3rem;margin-top: 0.85rem;"></div>
        </div>
        
      </div>
    </div>
    <div class="salesDistars">
      <img class="bgimgs" src="../assets/images/sales.jpg" alt="" />
      <div class="salesDistars_center">
        <div class="sale_baner_hear">
          {{ $t("message.SalesDistributor") }}
          <div class="sale_baner_lsit"></div>
        </div>
        <div class="sale_banner">
          <!-- <el-carousel indicator-position="outside" height="2.3rem" :autoplay="false">
            <el-carousel-item v-for="(item, index) in lsit" :key="index" >
              <div class="item_lsit">
                <img class="item_lsit_left" :src="item.imgOne" alt="" />
                <div class="item_lsit_right">
                  <div class="right_title">{{ $t(item.title) }}</div>
                  <div class="right_posion">
                    <img :src="item.imgTwo" alt="" /><span>{{
                      $t(item.position)
                    }}</span>
                  </div>
                  <div class="right_phone">
                    <img :src="item.imgTher" alt="" /><span>{{
                      item.ipchones
                    }}</span
                    ><span v-if="item.ipchonesone"
                      >, {{ item.ipchonesone }}</span
                    >
                  </div>
                  <div class="right_email">
                    <img :src="item.imgFover" alt="" /><span>{{
                      item.email
                    }}</span>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel> -->
          <div
            class="fot_swiper"
            @mouseenter="on_bot_enter"
            @mouseleave="on_bot_leave"
          >
            <swiper :options="swiperOptions" ref="mySwiper">
              <swiper-slide v-for="(item, index) in lsit" :key="index">
                <!-- <div class="sild">
		          <img :src="item.imgs" />
		        </div> -->
                <div class="item_lsit">
                  <img class="item_lsit_left" :src="item.imgOne" alt="" />
                  <div class="item_lsit_right">
                    <div class="right_title">{{ $t(item.title) }}</div>
                    <div class="right_posion">
                      <img :src="item.imgTwo" alt="" /><span>{{
                        $t(item.position)
                      }}</span>
                    </div>
                    <div class="right_phone" v-if="index == 4">
                      <img :src="item.imgTher" alt="" /><span>{{
                        $t(item.ipchoneses)
                      }}</span>
                    </div>
                    <div class="right_phone" v-else>
                      <img :src="item.imgTher" alt="" /><span>{{
                        $t(item.ipchones)
                      }}</span>
                    </div>
                    <div class="right_email">
                      <img :src="item.imgFover" alt="" /><span>{{
                        item.email
                      }}</span>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="Employment">
      <div class="employment_top">
        <div class="employment_top_left">
          {{ $t("message.Employment") }}
          <div class="top_boder"></div>
        </div>
        <div class="employment_top_right">
          <!-- {{ $t("message.Employmenttext") }} -->
        </div>
      </div>
      <div class="employment_bottom">
        <div
          class="employment_bottom_list"
          v-for="(item, index) in employmentList"
          :key="index"
          @click="tojoinus(item.id)"
        >
          <img class="botm_img" :src="item.img" alt="" />
          <div class="employment_bottom_list_posis">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="cooper_form">
      <img src="../assets/images/worldwide.png" alt="" />
      <div class="cooper_form_cener">
        <div class="cooper_form_cener_title">
          {{ $t("message.BayHubWorldwide") }}
          <div class="cooper_cener_title_boder"></div>
        </div>
        <p class="fotm_p">
          {{ $t("message.BayHubWorldwidetext") }}
        </p>
        <div class="forms">
          <el-form ref="form" :model="formData" label-width="4rem">
            <el-form-item :label="$t(Name) + '：'">
              <el-input
                v-model="formData.name"
                style="width: 7.3rem"
              ></el-input>
              <span style="color: red; padding-left: 0.15rem">*</span>
            </el-form-item>
            <el-form-item :label="$t(Company) + '：'">
              <el-input
                v-model="formData.company"
                style="width: 7.3rem"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t(City) + '：'">
              <el-input
                v-model="formData.city"
                style="width: 7.3rem"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t(Country) + '：'">
              <el-input
                v-model="formData.country"
                style="width: 7.3rem"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t(email) + '：'">
              <el-input
                v-model="formData.email"
                style="width: 7.3rem"
              ></el-input
              ><span style="color: red; padding-left: 0.15rem">*</span>
            </el-form-item>
            <el-form-item :label="$t(Briefdescription) + '：'">
              <el-input
                v-model="formData.content"
                style="width: 7.3rem"
              ></el-input
              ><span style="color: red; padding-left: 0.15rem">*</span>
            </el-form-item>
            <el-form-item :label="$t(Code) + '：'">
              <div class="int_imgs">
                <el-input v-model="codeNum" style="width: 6rem"></el-input>
                <div @click="refreshCode" class="list_top">
                  <Indetify :identifyCode="identifyCode"></Indetify>
                </div>
                <span style="color: red; padding-left: 0.2rem">*</span>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">{{
                $t("message.Submit")
              }}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <topsa></topsa>
    <Tooters></Tooters>
  </div>
</template>

<script>
import Language from "../components/language";
import HeaderNav from "../components/headernav";
import Tooters from "../components/footers";
import topsa from "../components/topsa.vue";
import Indetify from "../components/indetify";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "contactus",
  components: {
    Language,
    HeaderNav,
    Tooters,
    Swiper,
    SwiperSlide,
    topsa,
    Indetify,
  },
  data() {
    return {
      autoplay: false,
      employmentList: [],
      lsit: [
        // {
        //   imgOne: require("../assets/images/picsal.png"),
        //   imgTwo: require("../assets/images/posiom.png"),
        //   imgTher: require("../assets/images/black.png"),
        //   imgFover: require("../assets/images/email.png"),
        //   title: "message.salestitleone",
        //   position: "message.salesposition",
        //   ipchones: "+886-2-2659-0303",
        //   email: "sylvia@promate.com.tw",
        // },
        {
          imgOne: require("../assets/images/picsal.png"),
          imgTwo: require("../assets/images/posiom.png"),
          imgTher: require("../assets/images/black.png"),
          imgFover: require("../assets/images/email.png"),
          title: "message.salestitletwo",
          position: "message.salespositiontwo",
          ipchones: "+ 886-2-2659-0303",
          email: "sylvia@promate.com.tw",
        },
        {
          imgOne: require("../assets/images/picsal.png"),
          imgTwo: require("../assets/images/posiom.png"),
          imgTher: require("../assets/images/black.png"),
          imgFover: require("../assets/images/email.png"),
          title: "message.salestitlether",
          position: "message.salespositionther",
          ipchones: "+86-755-2557-3919",
          email: "frank@promate.com.tw",
        },
        {
          imgOne: require("../assets/images/picsal.png"),
          imgTwo: require("../assets/images/posiom.png"),
          imgTher: require("../assets/images/black.png"),
          imgFover: require("../assets/images/email.png"),
          title: "message.salestitlefover",
          position: "message.salespositionfover",
          ipchones: "+82-2-562-0970",
          email: "jhkang＠mikasa.co.kr",
        },
        {
          imgOne: require("../assets/images/picsal.png"),
          imgTwo: require("../assets/images/posiom.png"),
          imgTher: require("../assets/images/black.png"),
          imgFover: require("../assets/images/email.png"),
          title: "message.salestitlefive",
          position: "message.salespositionfive",
          ipchones: "message.salesphonefive",
          email: "sano837@mikasa.co.jp",
        },
        {
          imgOne: require("../assets/images/picsal.png"),
          imgTwo: require("../assets/images/posiom.png"),
          imgTher: require("../assets/images/black.png"),
          imgFover: require("../assets/images/email.png"),
          title: "message.salestitlesex",
          position: "message.salespositionsex",
          ipchones: "+81-3-5484-7395",
          ipchonesone: "03-5484-7395",
          ipchoneses: "message.salesphone",
          email: "arimoto-m@sanshin.co.jp",
        },
        // {
        //   imgOne: require("../assets/images/picsal.png"),
        //   imgTwo: require("../assets/images/posiom.png"),
        //   imgTher: require("../assets/images/black.png"),
        //   imgFover: require("../assets/images/email.png"),
        //   title: "message.salestitlesens",
        //   position: "message.salespositionsens",
        //   ipchones: "+86-755-8356-2772",
        //   ipchonesone: "+86-755-8330-7119",
        //   email: "Wesley_wong@maxtek.icrep.com.tw",
        // },
      ],
      positionList: [
        {
          imgOne: require("../assets/images/office.png"),
          imgTwo: require("../assets/images/offic2.png"),
          title: "message.positionListtitlefive",
          posiImgOne: require("../assets/images/dizhi.png"),
          posiImgTwo: require("../assets/images/posiom.png"),
          positiomtext: "message.positionListpositiomfivetext",
          callImgOne: require("../assets/images/call.png"),
          callImgTwo: require("../assets/images/black.png"),
          phone: "+81-3-6809-2967",
        },

        // {
        //   imgOne: require("../assets/images/office.png"),
        //   imgTwo: require("../assets/images/offic2.png"),
        //   title: "message.positionListtitleOne",
        //   posiImgOne: require("../assets/images/dizhi.png"),
        //   posiImgTwo: require("../assets/images/posiom.png"),
        //   positiomtext: "message.positionListpositiomOnetext",
        //   callImgOne: require("../assets/images/call.png"),
        //   callImgTwo: require("../assets/images/black.png"),
        //   phone: "+86-027-8753-8577",
        // },
        {
          imgOne: require("../assets/images/office.png"),
          imgTwo: require("../assets/images/offic2.png"),
          title: "message.positionListtitlefove",
          posiImgOne: require("../assets/images/dizhi.png"),
          posiImgTwo: require("../assets/images/posiom.png"),
          positiomtext: "message.positionListpositiomfovetext",
          callImgOne: require("../assets/images/call.png"),
          callImgTwo: require("../assets/images/black.png"),
          phone: "+ 1-408-562-6180",
        },
        {
          imgOne: require("../assets/images/office.png"),
          imgTwo: require("../assets/images/offic2.png"),
          title: "message.positionListtitlether",
          posiImgOne: require("../assets/images/dizhi.png"),
          posiImgTwo: require("../assets/images/posiom.png"),
          positiomtext: "message.positionListpositiomthertext",
          callImgOne: require("../assets/images/call.png"),
          callImgTwo: require("../assets/images/black.png"),
          phone: "+886-2-2517-8986",
        },

        {
          imgOne: require("../assets/images/office.png"),
          imgTwo: require("../assets/images/offic2.png"),
          title: "message.positionListtitle",
          posiImgOne: require("../assets/images/dizhi.png"),
          posiImgTwo: require("../assets/images/posiom.png"),
          positiomtext: "message.positionListpositiomtext",
          callImgOne: require("../assets/images/call.png"),
          callImgTwo: require("../assets/images/black.png"),
          phone: "+86-512-66674431",
        },
        {
          imgOne: require("../assets/images/office.png"),
          imgTwo: require("../assets/images/offic2.png"),
          title: "message.positionListtitleTwo",
          posiImgOne: require("../assets/images/dizhi.png"),
          posiImgTwo: require("../assets/images/posiom.png"),
          positiomtext: "message.positionListpositiomTwotext",
          callImgOne: require("../assets/images/call.png"),
          callImgTwo: require("../assets/images/black.png"),
          phone: "",
        },
      ],
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        observer: true,
        observeParents: true,
        setWrapperSize: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      Name: "message.Name",
      Title: "message.Title",
      Company: "message.Company",
      City: "message.City",
      State: "message.State",
      Country: "message.Country",
      email: "message.email",
      Briefdescription: "message.Briefdescription",
      Code: "message.Code",
      identifyCode: "",
      identifyCodes: "1234567890",
      formData: {
        city: "",
        company: "",
        content: "",
        country: "",
        email: "",
        name: "",
        state: "",
        title: "",
        remark: "",
      },
      codeNum: "",
    };
  },
  created() {
    this.$bus.$on("languages", (v) => {
      this.listApi();
    });
  },
  beforDestroy() {
    this.$bus.$off("languages"); //当这个组件销毁的时候bus也跟着一起销毁
  },

  mounted() {
    this.listApi();
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  methods: {
    on_bot_enter() {
      this.$refs.mySwiper.$swiper.autoplay.stop();
    },
    on_bot_leave() {
      this.$refs.mySwiper.$swiper.autoplay.start();
    },
    tojoinus(id) {
      this.$router.push({ path: "/joinus?id=" + id });
    },
    listApi() {
      this.$axios
        .get(this.$urls + "/recruit/list?come=0" + "&limit=100" + "&page=1")
        .then((response) => {
          this.employmentList = response.data.result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    onSubmit() {
      if (
        this.formData.name == "" ||
        this.formData.email == "" ||
        this.formData.content == "" ||
        this.codeNum == ""
      ) {
        this.$message.error(this.$t("message.messages"));
        return;
      }
      if (this.codeNum == this.identifyCode) {
        this.formApi();
      } else {
        this.$message.error(this.$t("message.Verification"));
      }
    },
    formApi() {
      this.$axios
        .post(this.$urls + "/box/save", this.formData)
        .then((response) => {
          if (response.data.code === 200) {
            this.$message({
              message: "submit successfully",
              type: "success",
            });
            this.formData.city = "";
            this.formData.company = "";
            this.formData.content = "";
            this.formData.country = "";
            this.formData.email = "";
            this.formData.name = "";
            this.formData.state = "";
            this.formData.title = "";
            this.formData.remark = "";
            this.codeNum = "";
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.news_bg {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    display: block;
  }
  .center_text {
    position: absolute;
    width: 14.55rem;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 888;
    font-size: 0.7rem;
    font-weight: 600;
    color: #f6f6f6;
    display: flex;
    align-items: center;
  }
}
.lagins {
  width: 14.55rem;
  margin-top: 0.5rem;
  position: absolute;
  right: 2.4rem;
  top: 0;
  z-index: 9999 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header_left {
    display: flex;
    align-items: center;
    .lagins_Language {
      cursor: pointer;
      width: 0.6rem;
      height: 0.6rem;
    }
  }
}
.contact_us {
  background: rgba(255, 255, 255, 0.6);
  .contact_center {
    width: 14.55rem;
    margin: 0 auto;
    .contact__title {
      width: 100%;
      text-align: center;
      font-size: 0.36rem;
      font-weight: 600;
      color: #072754;
      margin-top: 0.76rem;
      .contact__title_headers {
        width: 0.57rem;
        height: 0.07rem;
        background: #6a79f7;
        margin: 0 auto;
        margin-top: 0.33rem;
      }
    }
    .contact__top_list {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      justify-content: space-between;
      .contact__top_list_lis {
        cursor: pointer;
        width: 3.5rem;
        height: 3.35rem;
        padding: 0.3rem;
        margin-top: 0.85rem;
        background-color: #ffffff;
        box-shadow: 0 0.25rem 0.5rem 0 rgba(27, 27, 126, 0.09);
        border-radius: 0.05rem;
        .imgs1 {
          width: 0.81rem;
          height: 0.81rem;
          display: block;
        }
        .imgs2 {
          display: none;
        }
        .position_name {
          font-size: 0.2rem;
          font-weight: 600;
          color: #072754;
          line-height: 0.32rem;
          margin-top: 0.2rem;
        }
        .position_mass {
          font-size: 0.16rem;
          font-weight: 400;
          color: #58657e;
          line-height: 0.26rem;
          margin-top: 0.3rem;
          .position_mass_list {
            display: flex;
            // align-items: center;
            img {
              width: 0.2rem;
              height: 0.2rem;
            }
            .imgsOne {
              display: block;
            }
            .imgsTwo {
              display: none;
            }
            p {
              width: 95%;
              margin-left: 0.17rem;
              height: 1.2rem;
            }
          }
        }
      }
      .contact__top_list_lis:hover {
        background: linear-gradient(-70deg, #083085, #0d58c0);
        box-shadow: 0 0.25rem 0.76rem 0.03rem rgba(27, 27, 126, 0.22);
        border-radius: 0.05rem;
      }
      .contact__top_list_lis:hover .imgs1 {
        display: none;
      }
      .contact__top_list_lis:hover .imgs2 {
        display: block;
      }
      .contact__top_list_lis:hover .position_name {
        color: #ffffff;
      }
      .contact__top_list_lis:hover .position_mass .imgsOne {
        display: none;
      }
      .contact__top_list_lis:hover .position_mass .imgsTwo {
        display: block;
      }
      .contact__top_list_lis:hover .position_mass {
        color: #ffffff;
      }
    }
  }
}
.salesDistars {
  width: 100%;
  height: 5.25rem;
  margin-top: 1rem;
  position: relative;
  .bgimgs {
    width: 100%;
    height: 100%;
  }
  .salesDistars_center {
    width: 14.55rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    .sale_baner_hear {
      margin-top: 0.74rem;
      text-align: center;
      font-size: 0.36rem;
      font-weight: 600;
      color: #ffffff;
      .sale_baner_lsit {
        width: 0.57rem;
        height: 0.07rem;
        margin: 0 auto;
        margin-top: 0.33rem;
        background: #00d577;
      }
    }
    .sale_banner {
      margin-top: 0.3rem;
    }
  }
}
/deep/ .el-carousel__item {
  height: 2.97rem;
}
.item_lsit {
  display: flex;
  align-items: center;
  justify-content: center;
  .item_lsit_left {
    width: 3.06rem;
    height: 2.97rem;
  }
  .item_lsit_right {
    font-size: 0.16rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 0.26rem;
    opacity: 0.8;
    .right_title {
      font-size: 0.24rem;
      font-weight: 600;
      color: #ffffff;
      line-height: 0.32rem;
    }
    .right_posion {
      margin-top: 0.37rem;
      img {
        width: 0.15rem;
        height: 0.21rem;
        margin-right: 0.17rem;
      }
    }
    .right_phone {
      margin-top: 0.28rem;
      img {
        width: 0.19rem;
        height: 0.19rem;
        margin-right: 0.17rem;
      }
    }
    .right_email {
      margin-top: 0.28rem;
      img {
        width: 0.21rem;
        height: 0.16rem;
        margin-right: 0.17rem;
      }
    }
  }
}
.Employment {
  width: 14.55rem;
  margin: 0 auto;
  .employment_top {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    margin-top: 0.7rem;
    .employment_top_left {
      font-size: 0.36rem;
      font-weight: 600;
      color: #072754;
      line-height: 0.6rem;
      .top_boder {
        width: 0.57rem;
        height: 0.07rem;
        margin-top: 0.2rem;
        background: #6a79f7;
      }
    }
    .employment_top_right {
      width: 7.04rem;
      font-size: 0.14rem;
      font-weight: 400;
      color: #072754;
      line-height: 0.24rem;
      opacity: 0.8;
    }
  }
  .employment_bottom {
    width: 100%;
    margin-top: 1.03rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .employment_bottom_list {
      cursor: pointer;
      width: 3.03rem;
      height: 2.9rem;
      border-radius: 0.08rem;
      overflow: hidden;
      position: relative;
      .botm_img {
        width: 100%;
        height: 100%;
      }
      .employment_bottom_list_posis {
        position: absolute;
        height: 0.83rem;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: #08338e;
        opacity: 0.7;
        text-align: center;
        line-height: 0.83rem;
        font-size: 0.2rem;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .employment_bottom_list:hover .employment_bottom_list_posis {
      opacity: 1;
    }
  }
}
.fot_swiper {
  margin-top: 0.59rem;
  padding: 0 2.37rem;
  box-sizing: border-box;
  position: relative;
  /deep/ .swiper-button-prev,
  .swiper-button-next {
    color: #00d577;
    font-weight: bold;
    outline: none;
  }
  /deep/ .swiper-button-prev {
    left: 1rem;
  }
  /deep/ .swiper-button-next {
    right: 1rem;
  }
  /deep/ .swiper-wrapper {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 2.32rem;
  }
  /deep/ .swiper-slide {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }

  /deep/.swiper-pagination-bullet-active {
    background-color: #00d577;
  }
}
/deep/ .swiper-pagination,
.swiper-pagination-bullet {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: -0.3rem;
  left: 0;
  span {
    width: 0.1rem;
    height: 0.1rem;
    background-color: #ffffff;
    opacity: 0.6;
    border-radius: 50%;
    margin-right: 0.1rem;
  }
}
.cooper_form {
  margin-top: 0.5rem;
  width: 100%;
  height: 9.64rem;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .cooper_form_cener {
    width: 14.55rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    .cooper_form_cener_title {
      font-size: 0.3rem;
      font-weight: 600;
      color: #072754;
      text-align: center;
      margin: 0 auto;
      margin-top: 0.94rem;
      .cooper_cener_title_boder {
        width: 0.57rem;
        height: 0.07rem;
        background: #6a79f7;
        margin: 0 auto;
        margin-top: 0.27rem;
      }
    }
    .fotm_p {
      font-size: 0.24rem;
      font-weight: 500;
      color: #072754;
      line-height: 0.58rem;
      text-align: center;
      margin-top: 0.53rem;
    }
    .forms {
      width: 14.46rem;
      margin: 0 auto;
      margin-top: 0.47rem;
      padding: 0.45rem;
      background: #ffffff;
      box-shadow: 0px 0.12rem 0.28rem 0.04rem rgba(7, 39, 135, 0.05);
      border-radius: 0.08rem;
      box-sizing: border-box;
      .el-input {
        font-size: 0.15rem;
        color: #606266;
      }
      .el-form-item__label {
        font-size: 0.15rem !important;
      }
    }
  }
}
.imgbanner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 4.64rem;
  }
}
.imgbanner img {
  cursor: pointer;
  transition: all 0.6s;
}
.imgbanner img:hover {
  transform: scale(1.3);
}
.int_imgs {
  display: flex;
  align-items: center;
}
.list_top {
  display: flex;
  align-items: center;
  margin-left: 0.1rem;
}
</style>