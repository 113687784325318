import Vue from "vue";
import messages from "./message";
// @ts-ignore
import VueI18n from 'vue-i18n'
// @ts-ignore
import ElementLocale from 'element-ui/lib/locale'


Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en', // set locale
    messages, // set locale messages
});

ElementLocale.i18n((key, value) => i18n.t(key, value));

export default i18n;