<template>
  <div>
    <div class="news_bg">
      <div class="lagins">
        <div>
          <img src="../assets/images/logo.png" alt="" />
        </div>
        <div class="header_left">
          <HeaderNav></HeaderNav>
          <div class="lagins_Language">
            <Language style="width: 100%; height: 100%"></Language>
          </div>
        </div>
      </div>
      <img src="../assets/images/picnews.jpg" alt="" />
    </div>
    <nav class="news_center">
      <div class="news_header">
        <span> {{ $t("message.NewsCenter") }}</span>
        <div class="news_header_border"></div>
      </div>
      <div class="news_lsit">
        <div
          v-for="(item, index) in nuwsList"
          :key="index"
          @click="todetails(item.id)"
        >
          <TimeShaft :datails="item"></TimeShaft>
        </div>
      </div>
      <div class="paginations">
        <el-pagination
          background
          :page-size="limit"
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :total="toall"
        >
        </el-pagination>
      </div>
    </nav>
	<topsa></topsa>
    <Tooters></Tooters>
  </div>
</template>

<script>
import Language from "../components/language";
import HeaderNav from "../components/headernav";
import TimeShaft from "../components/timeShaft";
import Tooters from "../components/footers";
import topsa from '../components/topsa.vue';
export default {
  name: "news",
  components: {
    Language,
    HeaderNav,
    TimeShaft,
    Tooters,
	topsa
  },
  data() {
    return {
      come: 0,
      limit: 6,
      page: 1,
      toall: 0,
      nuwsList: [],
    };
  },
  created() {
    this.$bus.$on("languages", (v) => {
      this.newsList();
    });
  },
  mounted() {
    this.newsList();
  },
  beforDestroy() {
    this.$bus.$off("languages"); //当这个组件销毁的时候bus也跟着一起销毁
  },
  methods: {
    todetails(id) {
      this.$router.push({ path: "/newsdetail?id=" + id });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.newsList();
      window.scrollTo(0, 700);
    },
    newsList() {
      this.$axios
        .get(
          this.$urls +
            "/news/list?come=" +
            this.come +
            "&limit=" +
            this.limit +
            "&page=" +
            this.page
        )
        .then((response) => {
          this.nuwsList = response.data.result;
          this.toall = response.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.paginations .el-pagination .btn-prev {
  width: 0.38rem !important;
  height: 0.38rem !important;
  font-size: 0.16rem;
}
/deep/.paginations .el-pagination .btn-next {
  width: 0.38rem !important;
  height: 0.38rem !important;
  font-size: 0.16rem;
}
/deep/ .el-pager li {
  height: 0.38rem;
  line-height: 0.38rem !important;
  width: 0.38rem;
  font-size: 0.16rem;
  margin: 0.09rem;
}
/deep/.el-pager .active {
  background-color: "#3448DF";
}
.news_bg {
  width: 100%;
  img {
    width: 100%;
	display: block;
  }
}
.lagins {
  width: 14.55rem;
  margin-top: 0.5rem;
  position: absolute;
  right: 2.4rem;
  top: 0;
  z-index: 9999 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header_left {
    display: flex;
    align-items: center;
    .lagins_Language {
		cursor: pointer;
      width: 0.6rem;
      height: 0.6rem;
    }
  }
}
.news_center {
  width: 14.55rem;
  margin: 0 auto;
  padding-top: 0.73rem;
  .news_header {
    width: 100%;
    font-size: 0.36rem;
    font-weight: 600;
    color: #072754;
    line-height: 0.6rem;
    text-align: center;
    .news_header_border {
      width: 0.57rem;
      height: 0.07rem;
      margin: 0 auto;
      background: #425ef5;
      margin-top: 0.22rem;
    }
  }
  .news_lsit {
    margin-top: 0.36rem;
  }
}
.paginations {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.44rem;
}
</style>