<template>
	<div>
		<div class="topsa" v-if="ishands" @click="lsitads">
		  <img src="../assets/images/zhiidng.png" alt="" />
		</div>
	</div>
</template>

<script>
	export default{
		name:'topsa',
		data() {
			return{
				ishands:false
			}
		},
		mounted() {
		  window.addEventListener("scroll", this.handleScroll);
		},
		methods:{
			lsitads() {
			  window.scrollTo(0, 0);
			},
			handleScroll(){
				var scrollTop = window.pageYOffset ||document.documentElement.scrollTop ||document.body.scrollTop;
				if (scrollTop > 1360) {
				  this.ishands = true;
				} else {
				  this.ishands = false;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
.topsa {
  width: 1rem;
  height: 1rem;
  position: fixed;
  bottom: 3.5rem;
  right: .5rem;
  z-index: 9999;
  cursor: pointer;
  img {
	width: 100%;
	height: 100%;
  }
}
</style>
