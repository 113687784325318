import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {Carousel,CarouselItem,Pagination,Form,FormItem,Input,Button,Message,Icon} from 'element-ui';
// import "lib-flexible-computer";
import i18n from "./assets/i18n";
import 'element-ui/lib/theme-chalk/index.css';
import './assets/common/font.css';
// import 'lib-flexible/flexible'
// import './assets/js/flexible';
import $ from 'jquery'
import './assets/js/turn';
import axios from 'axios'
import Bus from './assets/js/bug';
Vue.prototype.$bus = Bus;

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
// import 'swiper/swiper-bundle.css'

Vue.prototype.$axios=axios
Vue.prototype.$urls = "http://www.bayhubtech.com/api"
router.afterEach((to, from, next) => {

  window.scrollTo(0, 0);

  // 或

  // window.scroll(0, 0);

});

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.prototype.$ = $

Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Pagination);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Button);
Vue.use(Icon);
Vue.prototype.$message = Message;

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
