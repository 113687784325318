<template>
  <div>
    <div class="news_bg">
      <div class="lagins">
        <div>
          <img src="../assets/images/logo.png" alt="" />
        </div>
        <div class="header_left">
          <HeaderNav></HeaderNav>
          <div class="lagins_Language">
            <Language style="width: 100%; height: 100%"></Language>
          </div>
        </div>
      </div>
      <div class="center_text">
        <div class="center_text_title">{{ $t("message.JoinUs") }}</div>
        <!-- <div class="centers">
          
          <div class="center_text_top_text">
            {{ $t("message.JoinUstext") }}<br/>
            {{ $t("message.JoinUstextOne") }}<br/>
            {{ $t("message.JoinUstextTwo") }}
          </div>
        </div> -->
      </div>
      <img src="../assets/images/bannerzhaopin.jpg" alt="" />
    </div>
    <div class="joinus_center">
      <div class="joinus_center_postion">
        <div class="join_headers">{{ employmentDetail.title }}</div>
        <!-- <div class="neirong">
          {{ $t("message.Jobdescription") }}
          <div class="neirong_border"></div>
        </div> -->
        <div class="htmlcss" v-html="employmentDetail.content"></div>
        <div class="joinus_bottom">
          <div class="joinus_bottom_text">
            {{ $t("message.ContactUsto") }}
            <div class="joinus_bottom_oder"></div>
          </div>
          <div class="joinus_bottom_flex">
            <img class="img_left" src="../assets/images/touxiang.png" alt="" />
            <div class="joinus_bottom_right">
              <div class="position_mass_list"> {{ $t("message.webname") }}：www.bayhubtech.com</div>
              <div class="position_mass_list">
                {{ $t("message.jianlis") }}：info007@bayhubtech.com
              </div>
              <!-- <div>
                <div class="position_mass_list" style="display: flex">
                  <img
                    class="imgsOne"
                    style="width: 0.16rem; height: 0.2rem"
                    src="../assets/images/join2.png"
                    alt=""
                  />
                  <p>
                    {{ $t("message.ContactUsToPosition") }}
                  </p>
                </div>
                <div class="position_mass_list">
                  <img
                    class="imgsOne"
                    style="width: 0.16rem; height: 0.14rem"
                    src="../assets/images/joinu1.png"
                    alt=""
                  />
                  <p>cindy.yang@bayhubtech.com / lily.yang@bayhubtech.com</p>
                </div>
                <div class="position_mass_list">
                  <img
                    class="imgsOne"
                    style="width: 0.16rem; height: 0.16rem"
                    src="../assets/images/call.png"
                    alt=""
                  />
                  <p>027-87538577 / 135451295687 / 13788040147</p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <img class="imgss" src="../assets/images/xx2.png" />
    </div>
    <topsa></topsa>
    <Tooters></Tooters>
  </div>
</template>

<script>
import Language from "../components/language";
import HeaderNav from "../components/headernav";
import Tooters from "../components/footers";
import topsa from "../components/topsa.vue";
export default {
  name: "joinus",
  data() {
    return {
      employmentDetail: {},
    };
  },
  components: {
    Language,
    HeaderNav,
    Tooters,
    topsa,
  },
  created() {
    this.$bus.$on("languages", (v) => {
      this.datailsApi();
    });
  },
  beforDestroy() {
    this.$bus.$off("languages"); //当这个组件销毁的时候bus也跟着一起销毁
  },
  mounted() {
    this.datailsApi();
  },
  methods: {
    datailsApi() {
      this.$axios
        .get(this.$urls + `/recruit/detail/${this.$route.query.id}?come=0`)
        .then((response) => {
          this.employmentDetail = response.data.result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.htmlcss {
  font-size: 0.14rem;
  font-weight: 400;
  color: #072754;
  line-height: 0.3rem;
  margin-top: 0.58rem;
  /deep/ strong {
    font-size: 0.28rem;
  }
  /deep/ ol {
    margin-left: 0.15rem;
    li {
      list-style: inherit;
    }
  }
}
.news_bg {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    display: block;
  }
  .center_text {
    position: absolute;
    width: 14.55rem;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 888;
    // font-size: 116px;
    // font-weight: 600;
    // color: #f6f6f6;
    display: flex;
    align-items: center;
    .center_text_title {
      font-size: 0.72rem;
      font-weight: bold;
      color: #ffffff;
    }
    .centers {
      //margin-top: 0.5rem;

      .center_text_top_text {
        width: 10.38rem;
        //height: 0.72rem;
        font-size: 0.16rem;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.3rem;
        margin-top: 0.5rem;
      }
    }
  }
}
.footers {
  margin: 0;
}
.lagins {
  width: 14.55rem;
  margin-top: 0.5rem;
  position: absolute;
  right: 2.4rem;
  top: 0;
  z-index: 9999 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header_left {
    display: flex;
    align-items: center;
    .lagins_Language {
      cursor: pointer;
      width: 0.6rem;
      height: 0.6rem;
    }
  }
}
.joinus_center {
  width: 100%;
  min-height: 5rem;
  padding-bottom: 1.3rem;
  background-color: #ededed;
  position: relative;
  .imgss {
    width: 100%;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  .joinus_center_postion {
    position: relative;
    z-index: 9;
    width: 12rem;
    padding: 0.8rem 1.4rem;
    box-sizing: border-box;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 0.04rem;
    .joinus_bottom {
      margin-top: 0.9rem;
      .joinus_bottom_text {
        font-size: 0.24rem;
        font-weight: 500;
        color: #072754;
        .joinus_bottom_oder {
          width: 0.57rem;
          height: 0.07rem;
          margin-top: 0.15rem;
          background: #6a79f7;
        }
      }
    }
    .joinus_bottom_flex {
      display: flex;
      align-items: center;
      margin-top: 0.43rem;
      .img_left {
        width: 1.1rem;
        height: 1.1rem;
        margin-top: -0.2rem;
      }
      .joinus_bottom_right {
        margin-left: 0.47rem;
        .position_mass_list {
          width: 6.95rem;
          // margin-left: 0.17rem;
          font-size: 0.18rem;
          font-weight: 400;
          color: #072754;
          padding-bottom: 0.2rem;
          // display: flex;
          // flex-flow: row;
          // align-items: flex-start;
          // margin-bottom: 0.15rem;
          // img {
          //   width: 0.2rem;
          //   height: 0.2rem;
          // }
          // p {
          //   width: 6.95rem;
          //   margin-left: 0.17rem;
          //   font-size: 0.16rem;
          //   font-weight: 400;
          //   color: #072754;
          // }
        }
      }
    }
    .join_headers {
      font-size: 0.36rem;
      font-weight: bold;
      text-align: center;
      margin: 0 auto;
      color: #072754;
      line-height: 0.5rem;
      margin-bottom: 0.6rem;
    }
    .neirong {
      font-size: 0.24rem;
      font-weight: bold;
      color: #072754;
      .neirong_border {
        width: 0.57rem;
        height: 0.07rem;
        margin-top: 0.1rem;
        background: #6a79f7;
      }
    }
  }
}
</style>