<template>
  <div class="footers">
    <img src="../assets/images/dibu.png" alt="" />
    <div class="footers_center">
		<div class="footers_center_top_one" @click="routs">
		 {{ $t("message.Contactus") }}
		  <div class="botders"></div>
		</div>
		<div class="one">{{ $t("message.BayhubName")}} <span style="padding:0 0.2rem;font-size:0.18rem">{{ $t("message.youxiangs")}}：sales007@bayhubtech.com</span></div>
      <div class="footers_center_top">
        <!-- <div class="footers_center_top_two">
          <div class="two">
            {{ $t("message.suzhou")}}
          </div>
          <div class="two">{{ $t("message.hongkong")}}</div>
        </div>
        <div class="footers_center_top_ther">
          <div>
            {{ $t("message.wuhan")}}
          </div>
          <div class="footers_p">
            {{ $t("message.taiwan")}}
          </div>
        </div> -->
		<div>
			<span>{{ $t("message.positionListtitlefive")}}：{{$t("message.positionListpositiomfivetext")}}</span>
			<span>{{ $t("message.positionListtitlether")}}：{{$t("message.positionListpositiomthertext")}}</span>
			
		</div>
		<div>
			<!-- <span>{{ $t("message.positionListtitleOne")}}：{{$t("message.positionListpositiomOnetext")}}</span> -->
			<span>{{ $t("message.positionListtitle")}}：{{$t("message.positionListpositiomtext")}}</span>
			<span>{{ $t("message.positionListtitleTwo")}}：{{$t("message.positionListpositiomTwotext")}}</span>
		</div>
		<div>
			<span>{{ $t("message.positionListtitlefove")}}：{{$t("message.positionListpositiomfovetext")}}</span>
			
			
		</div>
      </div>
      <div class="footer_bottom">
        {{$t('message.banq')}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footers",
  methods:{
	  routs(){
		  if(this.$route.fullPath!='/contactus'){
			this.$router.push({ path: "/contactus" });
		  }
	  }
  }
};
</script>

<style lang="scss" scoped>
.footers {
  width: 100%;
  overflow: hidden;
  height: 4.3rem;
  position: relative;
  img{
    width:100%;
  }
  .footers_center {
    width: 14.55rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
	.footers_center_top_one {
		cursor: pointer;
	  width: 3rem;
	  font-size: 0.36rem;
	  font-weight: 600;
	  color: #ffffff;
	  line-height: 0.6rem;
	  margin-top: 0.3rem;
	  .botders {
	    width: 0.57rem;
	    height: 0.07rem;
	    margin-top: 0.1rem;
	    background: #00d577;
	  }
	}
	.one {
		margin-top: 0.3rem;
		margin-bottom: 0.1rem;
	  font-size: 0.24rem;
	  font-weight: 500;
	  color: #ffffff;
	  line-height: 0.58rem;
	}
    .footers_center_top {
      display: flex;
	  flex-flow: row;
	  justify-content: space-between;
	  div{
		  width: 4.5rem;
		  display: flex;
		  flex-flow: column;
		  justify-content: space-between;
		 font-size: 0.14rem;
		 font-weight: 400;
		 height: 1.4rem;
		 color: #ffffff;
		 line-height: 0.24rem;
		 opacity: 0.8;
		 
	  }
      .footers_center_top_two {
        width: 4.3rem;
        .two {
          font-size: 0.14rem;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.24rem;
          opacity: 0.8;
        }
      }
      .footers_center_top_ther {
        width: 6.2rem;
        margin-left: 0.6rem;
        font-size: 0.14rem;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.24rem;
        opacity: 0.8;
        .footers_p {
          margin-top: 0.1rem;
        }
      }
    }
    .footer_bottom {
      position: absolute;
      left: 0.05rem;
      bottom: 0.2rem;
      font-size: 0.14rem;
      font-weight: 400;
      color: #f6f6f6;
      line-height: 0.32rem;
    }
  }
}
</style>